import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Link from '@mui/joy/Link';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import Header from '../Components/Header.tsx';
import UserSecurity from './User_Security_V2.0/Main.tsx';
import Aircrafts_Engines from './Aircrafts-Engines/Main.tsx'

export default function Administrative() {
	document.body.style.backgroundColor = '#d6d3ce';
	document.title = 'Administrative'
	const tabStyle = {fontSize:'14px'}

	const [selectedTab, setSelectedTab] = React.useState (sessionStorage.getItem('admin_window')?sessionStorage.getItem('admin_window'):'SU');
	const handleChangeTab = (newValue) => {
		sessionStorage.setItem('admin_window', newValue ? newValue : 'SU');
		setSelectedTab(newValue);
	} 

	React.useEffect(() => {
		if (!sessionStorage.getItem('admin_window')) {
			sessionStorage.setItem('admin_window','SU');
		}
	}, []);
 

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minHeight: '100dvh', minWidth:'1400px' }}>
				<Header title='Administrative'/>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px: 2,
						pt:'calc(var(--Header-height))',
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '100dvh',
						gap: 1,
					}}
				>
					<Box>
						<Tabs
							value={selectedTab}
							onChange={(event, newValue) => handleChangeTab(newValue)}
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								minHeight:'850px'
							}}
						>
							<TabList >
								<Tab value="SU" sx={tabStyle}>
									Security Users
								</Tab>
								<Tab value="AE" sx={tabStyle}>
									Aircraft-Engines
								</Tab>
							</TabList>
							<TabPanel value="SU" sx={{p:'0px'}}>
								<UserSecurity/>
							</TabPanel>
							<TabPanel value="AE" sx={{p:'0px'}}>
								<Aircrafts_Engines/>
							</TabPanel>
						</Tabs>
					</Box>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
