import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import URL from '../../../../../../URL';

export default function AddEditForm({ selectedRow, reloadTable, setError }) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'14px' }
	const formControlStyle = { my: 1 }
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'16px'};

	const [editedData, setEditedData] = React.useState({
		id: selectedRow.id,
		groupName: selectedRow.groupName,
		groupSta: selectedRow.sta,
		windowName: selectedRow.windowName,
		windowPermission: selectedRow.windowPermission,
		moduleName: selectedRow.moduleName,
		modulePermission: selectedRow.modulePermission,
		activate: selectedRow.activate,
		remarks:selectedRow.remarks
	});
	const [groupList, setGroupList] = React.useState<{id:number, name:string, sta:string}[]>([]);
	const [permissionsList, setPermissionsList] = React.useState<{permission:string}[]>([]);
	const [inActiveWindowsList, setInActiveWindowsList] =React.useState();
	const [isGroupListOpen, setIsGroupListOpen] = React.useState(false);
	const [selectedGroup, setSelectedGroup] = React.useState({name:'', sta:''})
	const navigate = useNavigate();

	//Внесение изменений в data
	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	const handleOpenGroupList = () => {
		if (isGroupListOpen && selectedGroup.sta != '' && selectedGroup.name != '') {
			setEditedData({
				id: editedData.id,
				groupName: selectedGroup.name,
				groupSta: selectedGroup.sta,
				windowName: editedData.windowName,
				windowPermission: editedData.windowPermission,
				moduleName: editedData.moduleName,
				modulePermission: editedData.modulePermission,
				activate: editedData.activate,
				remarks:editedData.remarks
			})	
		}
		setIsGroupListOpen(!isGroupListOpen);
		setSelectedGroup({name:'', sta:''});
	};

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		const activateValue = isChecked ? 'YES' : 'NO';
		handleChange('activate', activateValue);
	};

	React.useEffect(() => {
		setEditedData({
			id: selectedRow.id,
			groupName: selectedRow.groupName,
			groupSta: selectedRow.groupSta,
			windowName: selectedRow.windowName,
			windowPermission: selectedRow.windowPermission,
			moduleName: selectedRow.moduleName,
			modulePermission: selectedRow.modulePermission,
			activate: selectedRow.activate,
			remarks:selectedRow.remarks
		});
	}, [selectedRow]);

	React.useEffect(() => {
		const fetchGroups = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Groups?$select=name,sta`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();	
				setGroupList(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchGroups();
	}, []);

	React.useEffect(() => {
		const fetchPermissions = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSWindowPermissions?$filter=modulePermission eq '${editedData.modulePermission}'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setPermissionsList(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchPermissions();
	}, [editedData.modulePermission]);

	React.useEffect(() => {
		const fetchInActiveWindows = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/InactiveWindows()?group='${editedData.groupName}'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setInActiveWindowsList(result);
			} else {
				errors(response.status);
			}
		};
		fetchInActiveWindows();
	}, [editedData.groupName]);
	
	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					moduleName:editedData.moduleName,
					windowName:editedData.windowName,
					permission:editedData.windowPermission,
					groupName:editedData.groupName,
					activate:editedData.activate,
					remarks:editedData.remarks
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/${selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					moduleName:editedData.moduleName,
					windowName:editedData.windowName,
					permission:editedData.windowPermission,
					groupName:editedData.groupName,
					activate:editedData.activate,
					remarks:editedData.remarks
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityWindows/${selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(403);
			break;
			case 404:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};

	return (
		<Box>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Security Group and InActive Windows to Add:
			</Typography>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Group Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.groupName}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={2} sx={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
					<Tooltip title="List of Security Group" variant="outlined">
						<Button
							variant='outlined'
							onClick={handleOpenGroupList}
							sx={{
                                mb:1,
								borderRadius:'4px',
								border:'1px solid black',
								color:'black'
							}}
						>
							<FormatListBulletedIcon/>
						</Button>
					</Tooltip>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Group STA:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.groupSta}
							onChange={(event) => handleChange('groupSta', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={12}>
				    {isGroupListOpen ? (
						<Sheet
							className="OrderTableContainer"
							variant="outlined"
							sx={{
								minWidth: '0px',
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								minHeight: 0,
								border: '1px solid black',
								height:'270px',
								bgcolor:'#848284',
								mx:1,
								mt:2
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="xBetween"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									'--TableCell-height': '24px',
									'--TableCell-paddingX': '5px',
									'--TableCell-borderColor': '#939293',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{groupList.map((row) => (
										<tr
											key={row.id}
											onDoubleClick={handleOpenGroupList}
											onClick={() => setSelectedGroup(row)}
											style={{ 
												backgroundColor: selectedRow === row ? '#00cfd6' : 'white',
												color: selectedRow === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.name}</td>
											<td style={rowStyle}>{row.sta}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Sheet>
					):(
                        <FormControl sx={formControlStyle}>
                            <FormLabel sx={labelStyle}>
                                List of InActive Windows Permission for Selected Group:
                            </FormLabel>
                            <Box
                                sx={{
                                    border:'1px solid #d2dbe4',
                                    height:'250px',
                                    maxHeight:'250px',
                                    mx:1,
                                    borderRadius:'0px',
                                    bgcolor:'white'
                                }}
                            >
                                <Table
                                    aria-labelledby="tableTitle"
                                    borderAxis="none"
                                    stickyHeader
                                    hoverRow
                                    sx={{
                                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                        '--TableCell-paddingY': '1px',
                                        "--TableCell-height": "24px",
                                        '--TableCell-paddingX': '5px',
                                        tableLayout: 'auto', 
                                        width:'auto',
                                        borderRadius:'0px'
                                    }}
                                >
                                    {inActiveWindowsList ? (
                                        <Typography
                                            sx={{
                                                ml:1,
                                                fontSize:'14px',
                                                whiteSpace:'wrap'	
                                            }}
                                        >
                                            Not Found InActive Windows for Selected Sequrity Group!
                                        </Typography>
                                    ):(
                                        <Box/>
                                    )}
                                    {/**
                                    <tbody>
                                        {moduleList.map((row) => (
                                            <tr
                                                key={row.id}
                                                //onClick={() => handleRowSelection(row)}
                                                style={{ 
                                                    backgroundColor: selectedRow === row ? '#00cfd6' : 'white',
                                                    color: selectedRow === row ? 'white' : 'black',
                                                }}
                                            >
                                                <td style={{...rowStyle, position: 'sticky', left: 0, backgroundColor: selectedRow === row ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
                                                <td style={rowStyle}>{row.groupName}</td>
                                                <td style={rowStyle}>{row.groupSta}</td>
                                                <td style={rowStyle}>{row.permission}</td>
                                                <td style={rowStyle}>{row.activate}</td>
                                                <td style={rowStyle}>{row.remarks}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    */}
                                </Table>
                            </Box>
                        </FormControl>
					)}
				</Grid>
			</Grid>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Security of Window Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={selectedRow.id? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={selectedRow.id? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Module Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.moduleName}
						/>
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Module Permission:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.modulePermission}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Window Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.windowName}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<Box
						sx={{
							mx:1
						}}
					>
						<Grid container spacing={0} sx={{ flexGrow: 1 }}>
							<Grid xs={5}>
								<Checkbox 
									label="Activate"
									variant="outlined"
									color='danger'
									defaultChecked 
									checked={editedData.activate == 'YES' ? true:false}
									onChange={handleCheckboxChange}
									sx={{
										color:'#840000',
										fontWeight:'bold'
									}}
								/>	
								<FormControl sx={formControlStyle}>
									<FormLabel sx={{...labelStyle, mx:0}}> 
										Permission:
									</FormLabel>
									<Box
										sx={{
											display:'flex',
											flexDirection:'column',
											height:'150px',
											overflow:'auto',
											p:1,
											mr:1,
											border:'1px solid black',
											bgcolor:'white'
										}}
									>
										{editedData.modulePermission == 'Full Control' && (
											<Checkbox 
												value='Full Control' 
												label='Full Control'
												color='neutral'
												variant='outlined'
												checked={editedData.windowPermission == 'Full Control'}
												onChange={(event) => handleChange('permission', event.target.value)}
												sx={{
													mb:0.5,
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
											/>
										)}
										{/**
										{permissionsList && (permissionsList.map((row) => (
											<Radio 
												value={row.permission} 
												label={row.permission}
												checked={row.permission == editedData.windowPermission}
												onChange={(event) => handleChange('permission', event.target.value)}
												sx={{
													'& span': {
														borderRadius: '0px',
													}
												}}
											/>
										)))}
										*/}		
									</Box>
								</FormControl>
							</Grid>
							<Grid xs={7}>
								<FormControl sx={formControlStyle}>
									<FormLabel sx={labelStyle}> 
										Remarks:
									</FormLabel>
									<Textarea 
										maxRows={9}
										defaultValue={selectedRow.description}
										sx={{
											...inputStyle, 
											height:'200px',
											color:'#000078',
											lineHeight: '1.2'
										}}
										value={editedData.remarks}
										onChange={(event) => handleChange('remarks', event.target.value)}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
