import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import URL from '../../../../../../URL.js';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'14px' }
	const formControlStyle = { my: 1 }
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'16px'};

	const [editedData, setEditedData] = React.useState({
		name: props.selectedRow.name,
		groupName: props.selectedRow.groupName,
		groupSta: props.selectedRow.sta,
		permission: props.selectedRow.permission,
		activate: props.selectedRow.activate,
		remarks:props.selectedRow.remarks
	});
	const [moduleList, setModuleList] = React.useState<{id:number, groupName:string, groupSta:string, permission: string, activate: string, remarks:string}[]>([]);
	const [isGroupListOpen, setIsGroupListOpen] = React.useState(false);
	const [selectedGroupForModule, setSelectedGroupForModule] = React.useState({});
	const [selectedGroup, setSelectedGroup] = React.useState({name:'', sta:''})
	const navigate = useNavigate();

	//Внесение изменений в data
	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	//Изменение чекбокса Activate
	const handleCheckboxChange = (event) => {
		const activateValue = event.target.checked ? 'YES' : 'NO';
		handleChange('activate', activateValue);
	};

	const handleOpenGroupList = () => {
		if (isGroupListOpen && selectedGroup.sta != '' && selectedGroup.name != '') {
			setEditedData({
				name: editedData.name,
				groupName: selectedGroup.name,
				groupSta: selectedGroup.sta,
				permission: editedData.permission,
				activate: editedData.activate,
				remarks:editedData.remarks
			})	
		}
		setIsGroupListOpen(!isGroupListOpen);
		setSelectedGroup({name:'', sta:''});
	};

	React.useEffect(() => {
		setEditedData({
			groupName: props.selectedRow.groupName,
			name: props.selectedRow.name,
			groupSta: props.selectedRow.groupSta,
			permission: props.selectedRow.permission,
			activate: props.selectedRow.activate,
			remarks: props.selectedRow.remarks
		});
		setModuleList(props.data.filter(row => row.name == props.selectedRow.name));
	}, [props.selectedRow]);
	
	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityModules`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					name:editedData.name,
					groupName:editedData.groupName,
					permission:editedData.permission,
					activate:editedData.activate,
					remarks:editedData.remarks
				})
			  }
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityModules/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					name:editedData.name,
					groupName:editedData.groupName,
					permission:editedData.permission,
					activate:editedData.activate,
					remarks:editedData.remarks
				})
			  }
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/SecurityModules/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(403);
			break;
			case 404:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};

	return (
		<Box>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Security of Modules Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={12}>
				<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							List of Active Security Groups for Selected Module:
						</FormLabel>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'300px',
								mx:1,
								borderRadius:'0px',
								bgcolor:'white'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Box>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Module Name:
						</FormLabel>
						<Autocomplete
							options={props.modules.map(data=> ({ label: data.name }))}
							value={props.modules.find(item => item.name === editedData.name) ? { label: editedData.name } : null}
							sx={inputStyle}
							onChange={(event, value) => handleChange('name', value ? value.label : '')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Group Name:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.groupName}
							onChange={(event) => handleChange('groupName', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={2} sx={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
					<Tooltip title="List of Security Group" variant="outlined">
						<Button
							variant='outlined'
							onClick={handleOpenGroupList}
							sx={{
								mb:1,
								borderRadius:'4px',
								border:'1px solid black',
								color:'black'
							}}
						>
							<FormatListBulletedIcon/>
						</Button>
					</Tooltip>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Group STA:
						</FormLabel>
						<Input
							size="sm"
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								'--Textarea-paddingBlock':0,
								'--Textarea-paddingInline':0,
								backgroundColor:'#d6d3ce',
							}} 
							value={editedData.groupSta}
							onChange={(event) => handleChange('groupSta', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					{isGroupListOpen ? (
						<Sheet
							className="OrderTableContainer"
							variant="outlined"
							sx={{
								minWidth: '0px',
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								minHeight: 0,
								border: '1px solid black',
								height:'275px',
								bgcolor:'#848284',
								mx:1
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="xBetween"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									'--TableCell-height': '24px',
									'--TableCell-paddingX': '5px',
									'--TableCell-borderColor': '#939293',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{props.groupList.map((row) => (
										<tr
											key={row.id}
											onDoubleClick={handleOpenGroupList}
											onClick={() => setSelectedGroup(row)}
											style={{ 
												backgroundColor: selectedGroup === row ? '#00cfd6' : 'white',
												color: selectedGroup === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.name}</td>
											<td style={rowStyle}>{row.sta}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Sheet>
					):(
						<Box
							sx={{
								mx:1
							}}
						>
							<Grid container spacing={0} sx={{ flexGrow: 1 }}>
								<Grid xs={5}>
									<Checkbox 
										label="Activate"
										variant="outlined"
										color='danger'
										checked={editedData.activate == 'YES' ? true:false}
										onChange={handleCheckboxChange}
										sx={{
											color:'#840000',
											fontWeight:'bold'
										}}
									/>	
									<FormControl sx={formControlStyle}>
										<FormLabel sx={{...labelStyle, mx:0}}> 
											Permission:
										</FormLabel>
										<Box
											sx={{
												display:'flex',
												flexDirection:'column',
												height:'150px',
												overflow:'auto',
												p:1,
												mr:1,
												border:'1px solid black',
												bgcolor:'white'
											}}
										>
											{props.permissionsList && (props.permissionsList.map((row) => (
												<Checkbox
													value={row.permission} 
													label={row.permission}
													color='neutral'
													variant='outlined'
													checked={row.permission == editedData.permission}
													onChange={(event) => handleChange('permission', event.target.value)}
													sx={{
														mb:0.5,
														'& span': {
															borderRadius: '0px',
														},
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
												/>
											)))}		
										</Box>
									</FormControl>
								</Grid>
								<Grid xs={7}>
								<FormControl sx={formControlStyle}>
									<FormLabel sx={labelStyle}> 
										Remarks:
									</FormLabel>
									<Textarea 
										maxRows={10}
										defaultValue={props.selectedRow.description}
										sx={{
											...inputStyle, 
											height:'240px',
											color:'#000078',
											lineHeight: '1.2'
										}}
										value={editedData.remarks}
										onChange={(event) => handleChange('remarks', event.target.value)}
									/>
								</FormControl>
								</Grid>
							</Grid>
						</Box>
					)}
				</Grid>
			</Grid>
		</Box>
	);
}
