import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../../URL.js';

export default function Aircraft_Model() {

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

	const headers =[{id:'model',name:'AC_Model'}, {id:'type',name:'AC_Type'},{id:'manufacturer',name:'AC_Manufacturer'},{id:'engineType',name:'Engine_Type'},{id:'engineManufacturer',name:'Engine_Manufacturer'},{id:'engineQty',name:'Engine_Qty'},{id:'apuType',name:'APU_Type'},{id:'apuManufacturer',name:'APU_Manufacturer'},{id:'apu',name:'APU'},{id:'etops',name:'ETOPS'},{id:'catIII',name:'CAT_III'},{id:'fuelKg',name:'Fuel_KG'},{id:'fuelLt',name:'Fuel_LT'},{id:'remarks',name:'Remarks'},{id:'active',name:'Active'}]

	const [data, setData] = React.useState<{id:number, model:string, type:string, manufacturer:string, engineType:string, engineManufacturer:string, engineQty:number, apuType:string, apuManufacturer:string, apu:string, etops:string, catIII:string, fuelKg:number, fuelLt:number, remarks:string, active:string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, model:string, type:string, manufacturer:string, engineType:string, engineManufacturer:string, engineQty:number, apuType:string, apuManufacturer:string, apu:string, etops:string, catIII:string, fuelKg:number, fuelLt:number, remarks:string, active:string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, model:'', type:'', manufacturer:'', engineType:'', engineManufacturer:'', engineQty:0, apuType:'', apuManufacturer:'', apu:'', etops:'', catIII:'', fuelKg:0, fuelLt:0, remarks:'', active:'Y'});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [engineTypes, setEngineTypes] = React.useState ([]);
    const [apuTypes, setApuTypes] = React.useState ([{}]);
	const [acTypes, setAcTypes] = React.useState ([]);
	const [showEditor, setShowEditor] = React.useState (true);
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftModels`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка Aircraft Types
	const fetchTypes = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftTypes/Names`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setAcTypes(result);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};	

	//Запрос списка Engine Types
	const fetchEngineTypes = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes?$filter=apu eq 'N'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setEngineTypes(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};
	
    //Запрос списка ApuTypes
	const fetchApuTypes= async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes?$filter=apu eq 'Y'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setApuTypes(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};
		
	React.useEffect(() => {	
		fetchData();
		fetchTypes();
		fetchEngineTypes();
		fetchApuTypes();
	}, []);


	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(key != 'active' && filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			} else {
				if (filters.active == 'Y') {filtered = filtered.filter(row => row.active == filters.active)}
			}
		}

		if (sort.lable === 'id' || sort.lable === 'engineQty' || sort.lable === 'fuelKg'|| sort.lable === 'fuelLt'){
			filtered = filtered.sort((a, b) => sort.order === 'asc' ? a[sort.lable] - b[sort.lable] : b[sort.lable]- a[sort.lable]);
		} else {
			filtered = filtered.sort((a, b) => sort.order === 'asc' ? a[sort.lable].localeCompare(b[sort.lable]) : b[sort.lable].localeCompare(a[sort.lable]));
		}

        setFilteredData(filtered);
    }, [data, filters, sort]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, model:'', type:'', manufacturer:'', engineType:'', engineManufacturer:'', engineQty:0, apuType:'', apuManufacturer:'', apu:'', etops:'', catIII:'', fuelKg:0, fuelLt:0, remarks:'', active:'Y'});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение сортировки
	const handleSort = (property, value) => {
		setSort({lable:property, order:value})
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftModels/Xlsx(){?$filter=active eq 'Y'}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Security_Users.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '65% 35%' : '1fr',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px:1,
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '85dvh',
						position:'relative'
					}}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0,
							mt:0.5
						}}
					>
						<Tooltip title='Create Excel Report'>
							<IconButton
								variant='plain'
								onClick={createExcel}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/excel.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Typography 
						sx={{ 
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif'
						}}
					>
						Aircraft Model Registration List:
					</Typography>			
					<Box
						className="SearchAndFilters-tabletUp"
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 1.5,
							alignItems:'flex-end',
							justifyContent:'center'
						}}
					>
						<Checkbox 
							variant="outlined"
							color="neutral" 
							label="Active Only"
							checked={filters.active == 'Y' ? true:false}
							onChange={(event) => handleChange('active', event.target.checked ? 'Y' : 'N')}
							sx={{
								mb:1,
								'& .css-r4cpcz-JoyCheckbox-checkbox': {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
								'& .css-n110n' : {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
							}}
						/>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={{
							display:'initial',
							width: '100%',
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: '700px',
							border: '1px solid black',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ID
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={{
													width:'60px',
													minWidth:'0px',
													height:'20px',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.model}
												onChange={(event) => handleChange('model', event.target.value)}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.type}
												onChange={(event) => handleChange('type', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.manufacturer}
												onChange={(event) => handleChange('manufacturer', event.target.value)}
												sx={{
													width:'auto',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineType))].filter(row => row).map(engineType => ({ label: engineType }))}
												onChange={(event, value) => handleChange('engineType', value ? value.label : '')}
												value={filters.engineType === '' ? null : { label: filters.engineType }}
												sx={{
													width:'130px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineManufacturer))].filter(row => row).map(engineManufacturer => ({ label: engineManufacturer }))}
												onChange={(event, value) => handleChange('engineManufacturer', value ? value.label : '')}
												value={filters.engineManufacturer === '' ? null : { label: filters.engineManufacturer }}
												sx={{
													width:'auto',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.engineQty}
												onChange={(event) => handleChange('engineQty', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'20px',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.apuType))].filter(row => row).map(apuType => ({ label: apuType }))}
												onChange={(event, value) => handleChange('apuType', value ? value.label : '')}
												value={filters.apuType === '' ? null : { label: filters.apuType }}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.apuType))].filter(row => row).map(apuType => ({ label: apuType }))}
												onChange={(event, value) => handleChange('apuType', value ? value.label : '')}
												value={filters.apuType === '' ? null : { label: filters.apuType }}
												sx={{
													width:'auto',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.apu))].filter(row => row).map(apu => ({ label: apu }))}
												onChange={(event, value) => handleChange('apu', value ? value.label : '')}
												value={filters.apu === '' ? null : { label: filters.apu }}
												sx={{
													width:'70px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.etops))].filter(row => row).map(etops => ({ label: etops }))}
												onChange={(event, value) => handleChange('etops', value ? value.label : '')}
												value={filters.etops === '' ? null : { label: filters.etops }}
												sx={{
													width:'90px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.catIII))].filter(row => row).map(catIII => ({ label: catIII }))}
												onChange={(event, value) => handleChange('catIII', value ? value.label : '')}
												value={filters.catIII === '' ? null : { label: filters.catIII }}
												sx={{
													width:'90px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.fuelKg}
												onChange={(event) => handleChange('fuelKg', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 0.01,
														min:0
													}
												}}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'20px',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.fuelLt}
												onChange={(event) => handleChange('fuelLt', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 0.01,
														min:0
													}
												}}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'20px',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={{
													width:'auto',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.active))].filter(row => row).map(active => ({ label: active }))}
												onChange={(event, value) => handleChange('active', value ? value.label : '')}
												value={filters.active === '' ? null : { label: filters.active }}
												sx={{
													width:'90px',
													minWidth:'0px',
													height:'100%',
													pl:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#00cfd6' : 'white',
											color: selectedRow.id === row.id ? 'white' : 'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.model}</td>
										<td style={rowStyle}>{row.type}</td>
										<td style={rowStyle}>{row.manufacturer}</td>
										<td style={rowStyle}>{row.engineType}</td>
										<td style={rowStyle}>{row.engineManufacturer}</td>
										<td style={rowStyle}>{row.engineQty}</td>
										<td style={rowStyle}>{row.apuType}</td>
										<td style={rowStyle}>{row.apuManufacturer}</td>
										<td style={rowStyle}>{row.apu}</td>
										<td style={rowStyle}>{row.etops}</td>
										<td style={rowStyle}>{row.catIII}</td>
                                        <td style={rowStyle}>{row.fuelKg}</td>
                                        <td style={rowStyle}>{row.fuelLt}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.active}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={{
							px:1,
							pb: 1,
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minWidth: 0,
							height: '85dvh',
							gap: 1,
						}}
					>
						<AddEditeForm
							selectedRow={selectedRow} 
							reloadTable={fetchData}
							setResponseStatus={setResponseStatus}
							engineTypes={engineTypes}
							apuTypes={apuTypes}
							acTypes={acTypes}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
