import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { 
	Table, 
	TableBody, 
	TableCell, 
	TableContainer, 
	TableHead, 
	TableRow, 
	Paper,
} from '@mui/material';

export default function ComponentsTable({components}) {
	const [hasMore, setHasMore] = useState(true);
	const itemsPerPage = 30;
	const [records, setRecords] = useState(itemsPerPage);

	const loadMore = () => {
		if (records === components.length) {
			setHasMore(false);
		} else {
			setTimeout(() => {
				setRecords(records + itemsPerPage);
			}, 0);
		}
	};

	const headersStyle = {
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
		minWidth: '50px', 
		padding:'0px 2px 0px 2px', 
		backgroundColor:'#d6d3ce'
	};

	const rowsStyles = {
		borderRight: '1px solid black', 
		borderBottom: '1px solid black',
		padding:'0px 2px 0px 2px', 
		whiteSpace: 'nowrap'
	};

  	return (
		<InfiniteScroll
			pageStart={0}
			loadMore={loadMore}
			hasMore={hasMore}
			loader={<h4 className="loader">Loading...</h4>}
			useWindow={false}
		>
			<TableContainer component={Paper}>
				<Table>
					<TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
						<TableRow>
							<TableCell style={headersStyle}>ID:</TableCell>
							<TableCell style={headersStyle}>AC_Reg:</TableCell>
							<TableCell style={headersStyle}>IPC_Pos:</TableCell>
							<TableCell style={headersStyle}>Position:</TableCell>
							<TableCell style={headersStyle}>PN:</TableCell>
							<TableCell style={headersStyle}>Description:</TableCell>
							<TableCell style={headersStyle}>BatchNumber:</TableCell>
							<TableCell style={headersStyle}>Serial_Number:</TableCell>
							<TableCell style={headersStyle}>Condition:</TableCell>
							<TableCell style={headersStyle}>Certificate_Type:</TableCell>
							<TableCell style={headersStyle}>Certificate_Date:</TableCell>
							<TableCell style={headersStyle}>Certificate_Approval_Ref:</TableCell>
							<TableCell style={headersStyle}>Certificate_Number:</TableCell>
							<TableCell style={headersStyle}>MFR_Date:</TableCell>
							<TableCell style={headersStyle}>SV_Date:</TableCell>
							<TableCell style={headersStyle}>FH_AC_Install:</TableCell>
							<TableCell style={headersStyle}>FC_AC_Install:</TableCell>
							<TableCell style={headersStyle}>Date_AC_Install:</TableCell>
							<TableCell style={headersStyle}>WP_Install:</TableCell>
							<TableCell style={headersStyle}>Remarks:</TableCell>
							<TableCell style={headersStyle}>FH_AC_Remove:</TableCell>
							<TableCell style={headersStyle}>FC_AC_Remove:</TableCell>
							<TableCell style={headersStyle}>Date_AC_Remove:</TableCell>
							<TableCell style={headersStyle}>WP:</TableCell>
							<TableCell style={headersStyle}>Status:</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{components.slice(0, records).map((row) => (
							<TableRow key={(row as any).id}>
								<TableCell style={rowsStyles}>{(row as any).id}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).acReg}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).ipcPos}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).position}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).pn}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).description}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).batchNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).serialNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).condition}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateType}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateDate}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateApprovalRef}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).mfrDate}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).svDate}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).fhAcInstall}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).fcAcInstall}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).dateAcInstall}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).wp_install}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).remarks}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).fhAcRemove}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).fcAcRemove}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).dataAcRemove}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).wp}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).status}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</InfiniteScroll>
  	);
}

