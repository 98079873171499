import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { 
	Table, 
	TableBody, 
	TableCell, 
	TableContainer, 
	TableHead, 
	TableRow, 
	Paper,
} from '@mui/material';

export default function StockTable({stockComponents}) {
	const [hasMore, setHasMore] = useState(true);
	const itemsPerPage = 20;
	const [records, setRecords] = useState(itemsPerPage);

	const loadMore = () => {
		if (records === stockComponents.length) {
			setHasMore(false);
		} else {
			setTimeout(() => {
				setRecords(records + itemsPerPage);
			}, 0);
		}
	};

	const headersStyle = {
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
		minWidth: '50px', 
		padding:'0px 2px 0px 2px', 
		backgroundColor:'#d6d3ce'
	};

	const rowsStyles = {
		borderRight: '1px solid black', 
		borderBottom: '1px solid black',
		padding:'0px 2px 0px 2px', 
		whiteSpace: 'nowrap'
	};

  	return (
		<InfiniteScroll
			pageStart={0}
			loadMore={loadMore}
			hasMore={hasMore}
			loader={<h4 className="loader">Loading...</h4>}
			useWindow={false}
		>
			<TableContainer component={Paper} style={{ width:'100%' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={headersStyle}>ID:</TableCell>
							<TableCell style={headersStyle}>Store:</TableCell>
							<TableCell style={headersStyle}>Store_Address:</TableCell>
							<TableCell style={headersStyle}>PN:</TableCell>
							<TableCell style={headersStyle}>Serial_Number:</TableCell>
							<TableCell style={headersStyle}>Description:</TableCell>
							<TableCell style={headersStyle}>BatchNumber:</TableCell>
							<TableCell style={headersStyle}>ExtBatchNumber:</TableCell>
							<TableCell style={headersStyle}>Unit:</TableCell>
							<TableCell style={headersStyle}>Qty:</TableCell>
							<TableCell style={headersStyle}>Reserved_Qty:</TableCell>
							<TableCell style={headersStyle}>Expire_Date:</TableCell>
							<TableCell style={headersStyle}>MFG_Date:</TableCell>
							<TableCell style={headersStyle}>Condition:</TableCell>
							<TableCell style={headersStyle}>Certificate_Number:</TableCell>
							<TableCell style={headersStyle}>Certificate_Type:</TableCell>
							<TableCell style={headersStyle}>Certificate_Date:</TableCell>
							<TableCell style={headersStyle}>Certificate_Approval_Ref:</TableCell>
							<TableCell style={headersStyle}>Remarks:</TableCell>
							<TableCell style={headersStyle}>Stored_Date:</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{stockComponents.slice(0, records).map((row) => (
							<TableRow key={(row as any).id}>
								<TableCell style={rowsStyles}>{(row as any).id}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).stock}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).storeAddress}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).pn}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).serialNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).description}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).batchNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).extBatchNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).unit}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).qty}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).reservedQty}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).expireDate}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).mfgDate}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).condition}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateNumber}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateType}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateDate}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).certificateApprovalRef}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).remarks}</TableCell>
								<TableCell style={rowsStyles}>{(row as any).storeDate}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</InfiniteScroll>
  	);
}

