import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import AdditionalInfoWnd from './Additional_Info_wnd.tsx';
import StaRegistration from './StaRegistration.tsx';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import URL from '../../../../../../URL.js';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'14px' }
	const formControlStyle = { my: 0.5 }

	const [editedData, setEditedData] = React.useState({id:0, code: '', name: '', sta: '', address: '', phone: '', fax: '', email: '', contact_1: '', contact_2: '', web: '', capability:''});
	const [openAdditionalInfo, setOpenAdditionalInfo] = React.useState(false);
	const [criteriaAdditionalWnd, setCriteriaAdditionalWnd] = React.useState('');
	const [isStaRegistrationOpen, setIsStaRegistrationOpen] = React.useState(false);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);
	const navigate = useNavigate();

	//Внесение изменений в data
	const handleChange = (property, value) => {
		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	

		setEditedData({ ...editedData, [property]: value });
	};

	React.useEffect(() => {
		setEditedData({
			id: props.selectedRow.id,
			code: props.selectedRow.code,
			name: props.selectedRow.name,
			sta: props.selectedRow.sta,
			address: props.selectedRow.address,
			phone: props.selectedRow.phone,
			fax: props.selectedRow.fax,
			email: props.selectedRow.email,
			contact_1: props.selectedRow.contact_1,
			contact_2: props.selectedRow.contact_2,
			web: props.selectedRow.web,
			capability: props.selectedRow.capability,
		});
	}, [props.selectedRow]);

	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/MroRegistrations/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				errors(response.status);
			}
		};
		fetchMeta();
	}, []);

	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/MroRegistrations`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: editedData.code,
					name: editedData.name,
					sta: editedData.sta,
					address: editedData.address,
					phone: editedData.phone,
					fax: editedData.fax,
					email: editedData.email,
					contact_1: editedData.contact_1,
					contact_2: editedData.contact_2,
					web: editedData.web,
					capability: editedData.capability,
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/MroRegistrations/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: editedData.code,
					name: editedData.name,
					sta: editedData.sta,
					address: editedData.address,
					phone: editedData.phone,
					fax: editedData.fax,
					email: editedData.email,
					contact_1: editedData.contact_1,
					contact_2: editedData.contact_2,
					web: editedData.web,
					capability: editedData.capability,
				})
			  }
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка обновления группы:', error));

	}

	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/MroRegistrations/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка удаления группы:', error));

	}

	const handleOperatorAdditionalInfo = (event) => {
		if (event.code === 'F1') {
			event.preventDefault(); 
			if (editedData.name) {
				setOpenAdditionalInfo(true);
				setCriteriaAdditionalWnd('operator');
			}
		}
	};
	
	const handleSTAAdditionalInfo = (event) => {
		if (event.code === 'F1') {
			event.preventDefault(); 
			if (editedData.sta) {
				setOpenAdditionalInfo(true);
				setCriteriaAdditionalWnd('sta');
			}
		}
	};

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		const activateValue = isChecked ? 'Y' : 'N';
		handleChange('active', activateValue);
	};

	const handleOpenStaRegistration = () => {
		setIsStaRegistrationOpen(!isStaRegistrationOpen);
	};

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(403);
			break;
			case 404:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};
	
	return (
		<Box 
			sx={{
				position:'relative'
			}}
		>
			{openAdditionalInfo &&(
				<AdditionalInfoWnd setOpen={setOpenAdditionalInfo} selectedOperator={editedData.name} selectedSta={editedData.sta} criteria={criteriaAdditionalWnd}/>
			)}
			{isStaRegistrationOpen &&(
				<StaRegistration setOpen={setIsStaRegistrationOpen} selectedMRO={editedData}/>
			)}
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				MRO Company Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.code && editedData.sta && editedData.name ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.code && editedData.sta && editedData.name ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip 
				title="MRO STA Registration" 
				variant="outlined"
			>
				<Button
					variant='outlined'
					onClick={handleOpenStaRegistration}
					disabled={!editedData.name ? true:false}
					sx={{
						borderRadius:'4px',
						border:'1px solid black',
						color:'black',
						position:'absolute',
						top:50,
						right:8
					}}
				>
					<AppRegistrationIcon/>
				</Button>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							MRO CODE:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.code}
							onChange={(event) => handleChange('code', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							STA - Main Office:
						</FormLabel>
						<Autocomplete
							options={props.staOffice.map(data => ({ label: data.code }))}
							value={props.staOffice.find(item => item.code === editedData.sta) ? { label: editedData.sta } : null}
							sx={{...inputStyle, backgroundColor:'#c6ffff'}}
							onChange={(event, value) => handleChange('sta', value ? value.label : '')}
							onKeyDown={handleSTAAdditionalInfo}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							MRO Name:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.name}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Address:
						</FormLabel>
						<Textarea
							maxRows={3}
							value={editedData.address}
							sx={{ 
								...inputStyle, 
								height: '80px',
								lineHeight: '1.2'  
							}}
							onChange={(event) => handleChange('address', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Phone:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.phone}
							onChange={(event) => handleChange('phone', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Fax:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.fax}
							onChange={(event) => handleChange('fax', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							E-Mail:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.email}
							onChange={(event) => handleChange('email', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'end'}}>
					<Typography
						sx={{
							ml:1,
							mt:0.5,
							color:'black',
							fontSize:'14px'
						}}
					>
						Contact 1:
					</Typography>
				</Grid>
				<Grid xs={9}>
					<Textarea
						maxRows={3}
						value={editedData.contact_1}
						sx={{ 
							...inputStyle, 
							height: '90px', 
							my:1,
							lineHeight: '1.2' 
						}}
						onChange={(event) => handleChange('contact_1', event.target.value)}
					/>
				</Grid>

				<Grid xs={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'end'}}>
					<Typography
						sx={{
							ml:1,
							my:0.5,
							color:'black',
							fontSize:'14px'
						}}
					>
						Contact 2:
					</Typography>
				</Grid>
				<Grid xs={9}>
					<Textarea
						maxRows={3}
						value={editedData.contact_2}
						sx={{ 
							...inputStyle, 
							height: '90px', 
							mt:1,
							lineHeight: '1.2'
						}}
						onChange={(event) => handleChange('contact_2', event.target.value)}
					/>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							WEB:
						</FormLabel>
						<Textarea
							maxRows={2}
							value={editedData.web}
							sx={{ ...inputStyle, height: '60px', }}
							onChange={(event) => handleChange('web', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Capability:
						</FormLabel>
						<Textarea
							maxRows={3}
							value={editedData.capability}
							sx={{ ...inputStyle, height: '80px', }}
							onChange={(event) => handleChange('capability', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
