import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Autocomplete from '@mui/joy/Autocomplete';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export default function Welcome(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'14px' }
	const formControlStyle = { my: 1 }
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

	return (
		<Box
            sx={{
                position:'absolute',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                flexGrow: 1,
                height:'95.63%',
                width:'100%',
            }}
        >
            <Box
                sx={{
                    position:'absolute',
                    display:'flex',
                    flexDirection:'column',
                }}
            >
                <Typography 
                    sx={{ 
                        cursor:'default',
                        fontSize:'30px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif'
                    }}
                >
                    WELCOME TO ADMINISTRATION MODULE
                </Typography>
                <Typography 
                    sx={{ 
                        cursor:'default',
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        mb:2
                    }}
                >
                    Users, Security, Operator, MRO, STA, Airline Codes - Registration Section
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("UR")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    1: Users Registration - (User's Identification, Access Password)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("SG")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    2: Security Groups - (Groups's Name, STA)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("UOF")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    3: Users - Operator - Fleet Registration (User's Access to Different Operators and Fleets)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("MS")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    4: Module Security (User's Access Level to Different Modules)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("WS")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    5: Window Security (User's Access Level to Different Screens)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("OR")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    6: Operator Registration (Operators Name, Codes, STA, Address, Contacts)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("MROR")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    7: MRO Registration (MRO Companies Name, Code, STA, Address, Contacts)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("STAR")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    8: STA Registration (STA Name, Code, Country)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("AC")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    9: Airline Codes (Airline Name, ICAO Code, IATA Code, Country)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("A")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    10: Attachments
                </Typography>
            </Box>
		</Box>
	);
}
