import * as React from 'react';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import GlobalStyles from '@mui/joy/GlobalStyles';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';

import PassIcon from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Person';
import OpenEyeIcon from '@mui/icons-material/Visibility';
import CloseEyeIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

import URL from '../URL';

let login;
let password;

interface FormElements extends HTMLFormControlsCollection {
	login: HTMLInputElement;
	password: HTMLInputElement;
	persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
  	readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
	const { onClick, ...other } = props;
	const { mode, setMode } = useColorScheme();
	const [mounted, setMounted] = React.useState(false);
	React.useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
	}

	return (
		<IconButton
		id="toggle-mode"
		size="sm"
		color="neutral"
		aria-label="toggle light/dark mode"
		{...other}
		onClick={(event) => {
			if (mode === 'light') {
			setMode('dark');
			} else {
			setMode('light');
			}
			onClick?.(event);
		}}
		>
		{mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
		</IconButton>
	);
}

function Logo() {
    const colorScheme = useColorScheme();
    const imageSource = colorScheme.mode === 'light' ? 'images/logo.svg' : 'images/logo_white.svg';

    return (
        <img width='260' src={imageSource} alt="" />
    );
}

export default function JoySignInSideTemplate() {
	document.body.style.backgroundColor = 'white';
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState({wrongPass:false, serverError:false});
	const navigate = useNavigate();

	//Проверка наличия "asid" в localStorage
	React.useEffect(() => {
		if (localStorage.getItem('asid')) {
			navigate('/');
		}
	}, []);

	// Функция для выполнения запроса к Sessions
	const performSessionRequest = (login, password) => {
		
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Sessions`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				login,
				password,
			})
		})
		.then((response) => 
			{
				if (!response.ok){
					if (response.status === 401) {
						setError(prevState => ({ ...prevState, wrongPass: true }));
						throw new Error("Wrong login or password");
					} else if (response.status === 500) {
						setError(prevState => ({ ...prevState, serverError: true }));
						throw new Error("Server error");
					}
				}
				return response.json();
			})
		.then((data) => {
			if (data) {
				localStorage.setItem('asid',data.asid);
				navigate('/');
			} else {
				setError(prevState => ({ ...prevState, serverError: true }));
				throw new Error("Server error");
			}
		})
		.then(()=>{
			SelfRequest();
		})
		.then(()=>{
			//Временный костыль для починки темы на главной странице
			localStorage.setItem('joy-mode', 'light');
		})
		.catch((error) => {
			console.error('Ошибка при выполнении запроса /Sessions:', error);
		});
	};

	//Запрос данных о пользователе 
	const SelfRequest = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self`, {  headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}})
		.then(response => response.json())
		.then(data => {
			localStorage.setItem('name', data.name);
			localStorage.setItem('login', data.login);
		})
		.catch(error => {
			console.error('Ошибка при выполнении запроса /Self:', error);
		});
	};

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange>
		<CssBaseline />
		<GlobalStyles
			styles={{
				':root': {
					'--Collapsed-breakpoint': '769px', 
					'--Cover-width': '50vw', 
					'--Form-maxWidth': '1000px',
				},
			}}
		/>
		{error.wrongPass && (
			<Box
				sx={{
					position: 'fixed',
					top: '20px',
					right: '20px',
					display: 'flex',
					alignItems: 'center',
					color: 'red',
					backgroundColor:'#FCE4E4',
					px:1,
					borderRadius:'7px',
					zIndex: 9999,

				}}
			>
				<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
				<Typography sx={{ mt: 0.3, color: 'red' }}>
					Wrong login or password!
				</Typography>
			</Box>
		)}
		{error.serverError && (
			<Box
				sx={{
					position: 'fixed',
					top: '20px',
					right: '20px',
					display: 'flex',
					alignItems: 'center',
					color: 'red',
					backgroundColor:'#FCE4E4',
					px:1,
					borderRadius:'7px',
					zIndex: 9999,

				}}
			>
				<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
				<Typography sx={{ mt: 0.3, color: 'red' }}>
					Server error!
				</Typography>
			</Box>
		)}
		<Box
			sx={(theme) => ({
				width:
					'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
				position: 'relative',
				zIndex: 1,
				display: 'flex',
				justifyContent: 'flex-end',
				backgroundColor: 'rgba(255 255 255)',
				[theme.getColorSchemeSelector('dark')]: {
					backgroundColor: 'rgba(0 0 0)',
				},
			})}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100dvh',
					width:
						'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
					maxWidth: '100%',
					px: 2,
				}}
			>
				<Box
					component="header"
					sx={{
						pt: 3,
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center', 
						justifyContent: 'space-between',
					}}
				>
					<ColorSchemeToggle />
				</Box>
				<Box
					component="main"
					sx={{
						my: 'auto',
						py: 2,
						pb: 5,
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						width: 400,
						maxWidth: '100%',
						mx: 'auto',
						borderRadius: 'sm',
						'& form': {
							display: 'flex', 
							flexDirection: 'column',
							gap: 2,
						},
						[`& .${formLabelClasses.asterisk}`]: {
							visibility: 'hidden',
						},
					}}
				>
					<Box sx={{ mx: 'auto', gap: 2, display: 'flex', alignItems: 'center', pb: 9}}>
						<Logo/>
					</Box>
					
					<Stack gap={4} sx={{ mt: 1 }}>
						<form
							onSubmit={(event: React.FormEvent<SignInFormElement>) => {
								event.preventDefault();
								const formElements = event.currentTarget.elements;
								login = formElements.login.value;
								password = formElements.password.value;
								performSessionRequest(login,password);
							}}
						>
							<FormControl required>
								<FormLabel>Login</FormLabel>
								<Input 
									type="login" 
									name="login" 
									color={error.wrongPass ? 'danger' : 'neutral'}
									startDecorator={<LoginIcon sx={{ width:"20px" }}/>} 
								/>
							</FormControl>
							<FormControl required>
								<FormLabel>Password</FormLabel>
								<Input
									type={showPassword ? 'text' : 'password'}
									name="password"
									color={error.wrongPass ? 'danger' : 'neutral'}
									startDecorator={<PassIcon sx={{ width:"20px" }}/>}
									endDecorator={
										<IconButton
											onClick={() => setShowPassword((prev) => !prev)}
											aria-label="toggle password visibility"
										>
											{showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
										</IconButton>
									}
								/>
							</FormControl>
							<Stack gap={4} sx={{ mt: 2 }}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									{/* <Link level="title-sm" href="#replace-with-a-link">
										Forgot your password?
									</Link> */}
										
								</Box>
								<Button type="submit" fullWidth>
									Sign in
								</Button>
							</Stack>
						</form>
						
					</Stack>
				</Box>
				<Box component="footer" sx={{ py: 3 }}>
					<Typography level="body-xs" textAlign="center">
					© Alaskar Technologies, Inc.{new Date().getFullYear()}
					</Typography>
				</Box>
			</Box>
		</Box>
		<Box
			sx={(theme) => ({
				height: '100%',
				position: 'fixed',
				right: 0,
				top: 0,
				bottom: 0,
				left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
				backgroundSize: 'cover',
				backgroundPosition: 'right',
				backgroundRepeat: 'no-repeat',
				transform: 'scaleX(-1)',
				backgroundImage:
					'url(images/engine.webp)',
				[theme.getColorSchemeSelector('dark')]: {
					transform: 'scaleX(1)',
					backgroundPosition: 'center',
					backgroundImage:
					'url(images/engine_black.avif)',
				},
			})}
		/>
		</CssVarsProvider>
	);
}