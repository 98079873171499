import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Button from "@mui/joy/Button";
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import AS_Submit_Opening_Initializing from './AS_Submit_Opening_Initializing.tsx';
import AS_Initializing from './AS_Initializing.tsx';

import URL from '../../../../../URL.js';

export default function Actual_Structure ({handleFindComponentsClick, data, permissions, setOpenMaintanaceData, openFilters, setSelectedAC}) { 
    const [selectedRow, setSelectedRow] = React.useState({id:'', asID: '', acReg:''});
    const [filterText, setFilterText] = React.useState('');
    const [submitingOpening, setSubmitingOpening] = React.useState(false);
    const [openInitializing, setOpenInitializing] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const navigate = useNavigate();

    const handleSelectButton = () => {
        if(selectedRow.id != ''){
            setSubmitingOpening(true);
        }
    };
    
    const handleFindComponents = () => {
		setOpenMaintanaceData(false);
        handleFindComponentsClick();
    };

	const handleManuals = () => {
		setOpenMaintanaceData(true);
		if(openFilters){
			handleFindComponentsClick();
		}
	};

    const handleRowSelection = (row) => {
        setSelectedRow(row);
		setSelectedAC(row);
        fetchData(row);
    };

    const handleFilterInputChange = (event) => {
        setFilterText(event.target.value);
    }

	React.useEffect(() => {
		if (!openInitializing) {
			handleRowSelection(selectedRow);
		}
	}, [openInitializing]);

    const fetchData = (row) => {
        return fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Initializing?filter=acId eq ${row.id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('asid')}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            if (Object.keys(data.value).length === 0) {
                setIsInitialized(false);
            } else {
                setIsInitialized(true);
            }
        })
        .catch(error => console.error('Ошибка при выполнении API-запроса:', error));
    };

	const handleExcelButton = () => {
		let url;
		if (filterText.trim() === '') {
			url = `${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure/Xlsx/''`;
		} else {
			url = `${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure/Xlsx/${filterText}`;
		}

		return fetch(url, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (!response.ok) {
				if (response.status === 401) {
					navigate('/SignIn');
					localStorage.removeItem('name');
					localStorage.removeItem('login');
					localStorage.removeItem('asid');
				} else if (response.status === 500) {
					setError(true);
					throw new Error("Server error");
				}
			} else {
				return response.blob(); 
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Actual_Structure.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			} else {
				throw new Error("Blob is undefined");
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	React.useEffect(() => {
        // Функция для сброса значения константы через 5 секунд
        const resetStatus = () => {
            setTimeout(() => {
                setError(false);
            }, 3000);
        };

        // Вызов функции при изменении значений error или successfulSave
        if (error) {
            resetStatus();
        }
    }, [error]);
	
	const filteredRows:any = data.filter((row) => {
		const values = Object.values(row).join('').toLowerCase(); 
		return values.includes(filterText.toLowerCase());
	});

	return (
		<CssVarsProvider disableTransitionOnChange>
			<>
				{submitingOpening &&(
					<AS_Submit_Opening_Initializing 
						setSubmitingOpening={setSubmitingOpening} 
						selectedRow={selectedRow} 
						setOpenInitializing={setOpenInitializing}
					/>
				)}
				{openInitializing &&(
					<AS_Initializing 
						AS={selectedRow} 
						setOpenInitializing={setOpenInitializing} 
					/>
				)}
				{error && (
					<Box
						sx={{
							position: 'fixed',
							top: '67px',
							right: '20px',
							display: 'flex',
							alignItems: 'center',
							color: 'red',
							backgroundColor:'#FCE4E4',
							px:1,
							borderRadius:'7px',
							zIndex: 9999,

						}}
					>
						<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
						<Typography sx={{ mt: 0.3, color: 'red' }}>
							Server error!
						</Typography>
					</Box>
				)}
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						height:'100%'
					}}
				>
					<Typography
						sx={{
							ml:2,
							mb:1,
							fontSize:'24px',
							fontWeight:'500',
							color:'black',
							height:'30px'
						}}
					>
						Actual Structure - Select Aircraft Reg. No. - SN:
					</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'auto 160px',
							height:'100%'
						}}
					>
						<Box
							sx={{
								display: 'grid',
								gridTemplateRows: 'auto 50px',
								//border: '2px solid green' ,
								height:'100%',
							}}
						>
							<Box
								sx={{
								// border: '1px solid grey' ,
									overflow: 'auto', 
									height: 'auto',
									backgroundColor:'white',
									mx:2,
									mb:0
								}}
							>
								<Table
									hoverRow
									noWrap
									size='sm'
									borderAxis='none'
									sx = {{
										tableLayout: 'auto', 
										textAlign:'center',
										"--TableCell-height": "1px",
										"--TableCell-paddingY": "0px",
										lineHeight:'21px'
									}}
								>
									
									<tbody>
										{filteredRows.map((row) => (
											<tr 
												key={row.id}
												onClick={() => handleRowSelection(row)} 
												style={{ 
													backgroundColor: selectedRow === row ? '#08246b' : 'white',
													color: selectedRow === row ? 'white' : '#000076',
													fontSize:'20px',
													fontWeight:'bold',
													textAlign:'left'
												}}
											>
												<td>{row.id}</td>
												<td>{row.acReg}</td>
												<td>{row.acsn}</td>
												<td>{row.acFamily}</td>
												<td>{row.acType}</td>
												<td>{row.operatorCodeICAO}</td>
												<td>{row.operatorName}</td>
												<td>{row.staOperatorBaseLocation}</td>
												<td>{row.Total_Cycles}</td>
												<td>{row.Total_Hours}</td>
												<td>{row.Total_Date}</td>
											</tr>
										))}
									</tbody>
								</Table>	
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems:'center',
									mr:2
								}}
							>
								<Button
									key='FindComponents'
									variant= "outlined"
									sx={{
										borderRadius: '0px',
										fontSize: '16px',
										fontWeight:'100',
										backgroundColor:'#c6c3ff',
										color:'black',
										width:'155px',
										height:'26px',
										minHeight:'0px',
										ml:2,
										borderTop:'1px solid white',
										borderRight:'1px solid #424142',
										borderBottom:'1px solid #424142',
										borderLeft:'1px solid white',
										paddingInline:0
									}}
									onClick={() => handleFindComponents()}
								>
									Find Components
								</Button>
								{selectedRow.id != '' ? (
									<Button
										key='Manuals'
										variant="outlined"
										onClick={handleManuals}
										sx={{
											borderRadius: '0px',
											fontSize: '16px',
											fontWeight:'100',
											backgroundColor:'#c6c3ff',
											color:'black',
											width:'97px',
											height:'26px',
											minHeight:'0px',
											ml:2,
											borderTop:'1px solid white',
											borderRight:'1px solid #424142',
											borderBottom:'1px solid #424142',
											borderLeft:'1px solid white',
											paddingInline:0
										}}
									>
										Manuals
									</Button>
								):(<Box sx={{width:'97px',ml:2,}}></Box>)}
								<Button
									key='FindComponents'
									variant="outlined"
									color="neutral"
									onClick={handleExcelButton}
									sx={{
										borderRadius: '0px',
										fontSize: '16px',
										fontWeight:'100',
										backgroundColor:'#c6c3ff',
										color:'black',
										width:'97px',
										height:'26px',
										minHeight:'0px',
										ml:2,
										borderTop:'1px solid white',
										borderRight:'1px solid #424142',
										borderBottom:'1px solid #424142',
										borderLeft:'1px solid white',
										paddingInline:0
									}}
								>
									Excel
								</Button>
								<Input 
									sx={{
										borderRadius:'0px',
										"--Input-focusedThickness": "0px",
										width:'135px',
										height:'26px',
										minHeight:'0px',
										ml:'auto',
										'& input': {
											textAlign: 'center',
										}
									}}
									onChange={handleFilterInputChange}
									value={filterText}
								/>                                    
							</Box>                                                           
						</Box>
						<Box
							sx={{
								display:'flex',
								flexDirection:'column',
								justifyContent:'space-between',
								mr:2
							}}	
						>  
							{isInitialized ? (
								<Box
									sx={{
										display:'flex',
										flexDirection:'column',
										justifyContent:'space-between',
									}}
								>
									<Button
										key='Planning'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											mb:1
										}}
									>
										Planning
									</Button>
									{permissions.some(permission => permission.window === "Actual") ? (
										<Button
											key='Actual'
											variant="outlined"
											color="neutral"
											sx={{
												borderRadius: '0px',
												fontSize: '18px',
												backgroundColor:'#dedff7',
												borderRight:'2px solid #424142',
												borderBottom:'2px solid #424142',
												borderLeft:'2px solid white',
												borderTop:'2px solid white',
												my:1
											}}
										>
											Actual
										</Button>
									):(
										<Box sx={{height:'43px',my:1}}/>
									)}
									{permissions.some(permission => permission.window === "Initializing") ? (
										<Button
											key='Initializing'
											variant="outlined"
											color="neutral"
											sx={{
												borderRadius: '0px',
												fontSize: '18px',
												backgroundColor:'#dedff7',
												borderRight:'2px solid #424142',
												borderBottom:'2px solid #424142',
												borderLeft:'2px solid white',
												borderTop:'2px solid white',
												my:1
											}}
										>
											Initializing
										</Button>
									):(
										<Box sx={{height:'43px',my:1}}/>
									)}
									<Button
										key='Reports'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											mt:5,
											mb:1
										}}
									>
										Reports
									</Button>
									<Button
										key='Engine LLP'
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											my:1
										}}
									>
										Engine LLP
									</Button>
								</Box>
							):(
								<Button
									onClick={handleSelectButton}
									key='Select'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
									}}
								>
									Select
								</Button>
							)}	
							<Box
								sx={{
									display:'flex',
									flexDirection:'column',
									justifyContent:'center',
									mb:1.5
								}}	
							>
								<Button
									key='Receipt Info AD, SB, etc.'
									variant="outlined"
									color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											my:1
										}}
								>
									Receipt Info AD, SB, etc.
								</Button>	
								<Button
									key='EC'
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '18px',
										backgroundColor:'#dedff7',
										borderRight:'2px solid #424142',
										borderBottom:'2px solid #424142',
										borderLeft:'2px solid white',
										borderTop:'2px solid white',
										mt:1
									}}
								>
									EC
								</Button>	
							</Box>                  
						</Box>

					</Box>
				</Box>
			</>
		</CssVarsProvider>
	);
}
