import * as React from 'react';

import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import { CssVarsProvider } from '@mui/joy/styles';

import ConstructionIcon from '@mui/icons-material/Construction';
import AddIcon from '@mui/icons-material/Add';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Textarea from '@mui/joy/Textarea';

import URL from '../../../../../URL.js';

export default function AC_Maintenance_Data({ setOpenMaintanaceData, selectedAC}) {
    const [data, setData] = React.useState({id:0, acId:null, manuals:'',adBiWeekly:''}); 
    const [error, setError] = React.useState(false);
    const [successfulSave, setSuccessfulSave] = React.useState(false);

    const handleClose = () => {
        setOpenMaintanaceData(false);
    };

    const handleManualsChange = (event) => {
		const newValue = event.target.value;

		// Проверяем, если длина текста больше 750 символов
		if (newValue.length <= 750) {
            setData((prevData) => ({...prevData, manuals: newValue}));
		}
	};

    const handleadBiWeeklyChange = (event) => {
		const newValue = event.target.value;

		// Проверяем, если длина текста больше 255 символов
		if (newValue.length <= 255) {
            setData((prevData) => ({...prevData, adBiWeekly: newValue}));
		}
	};

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/AmpManuals?$filter=acId eq ${selectedAC.id}`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
                const result = await response.json();
                if (result.value.length != 0) {
                    setData(result.value[0]);
                } else {
                    setData({id:0, acId:null, manuals:'',adBiWeekly:''})
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [selectedAC]);

    React.useEffect(() => {
        // Функция для сброса значения константы через 5 секунд
        const resetStatus = () => {
            setTimeout(() => {
                setError(false);
                setSuccessfulSave(false);
            }, 3000);
        };

        // Вызов функции при изменении значений error или successfulSave
        if (error || successfulSave) {
            resetStatus();
        }
    }, [error, successfulSave]);

    const handleSave = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/AmpManuals/${data.id}`, 
			{
				method: 'PUT',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					acId: selectedAC.id,
					manuals: data.manuals,
                    adBiWeekly: data.adBiWeekly
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				if (response.status === 500) {
					setError(true);
					throw new Error("Server error");
				}
			} else {
				setSuccessfulSave(true);
			}
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка сохранения AMPManual:', error));
	}

	return (
		<CssVarsProvider disableTransitionOnChange>
            {error && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '20px',
                        right: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'red',
                        backgroundColor:'#FCE4E4',
                        px:1,
                        borderRadius:'7px',
                        zIndex: 9999,

                    }}
                >
                    <Typography sx={{ mt: 0.3, color: 'black' }}>
                        Error saving record!
                    </Typography>
                </Box>
		    )}
            {successfulSave && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '65px',
                        right: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor:' #E4FCE4',
                        px:1,
                        borderRadius:'7px',
                        zIndex: 9999,

                    }}
                >
                    <Typography sx={{ mt: 0.3, color: 'black' }}>
                        Record saved successfully!
                    </Typography>
                </Box>
		    )}
			<Box
                sx={{
                    display: 'flex',
					flexDirection:'column',
                    height:'100%',
                    width:'100%',
                }}
            >        
                <Typography
					sx={{
						ml:2,
						fontSize:'24px',
						fontWeight:'500',
                        color:'black',
                        height:'30px'
					}}
				>
                    AC Maintanance Data:
                </Typography>
                <Box 
                    sx={{
						display:'flex',
                        flexDirection:'column',
						mb:1
					}}
                >
                    <Textarea 
                        maxRows={14}
						variant='outlined' 
                        value={data.manuals}
                        onChange={handleManualsChange}
						sx={{
                            '--Textarea-focusedInset': 0,
                            '--Textarea-focusedThickness': 0,
                            '--Textarea-paddingBlock':0,
							'--Textarea-paddingInline':0,
                            '--Textarea-focusedHighlight': 'black',
							width:'auto',
							height:'255px',
                            mx:2,
                            mt:2,
							minHeight:'0px',
							borderRadius:'0px',
							border:'1px solid black',
							fontSize:'18px',
							fontWeight:'600',
                            lineHeight: 1
						}}
					/>
                    <Typography
                        sx={{
                            ml:2,
                            fontSize:'24px',
                            fontWeight:'500',
                            color:'black',
                            height:'30px'
                        }}
                    >
                        AD BI-Weekly:
                    </Typography>
                    <Textarea 
                        maxRows={4}
						variant='outlined' 
                        value={data.adBiWeekly}
                        onChange={handleadBiWeeklyChange}
						sx={{
                            '--Textarea-focusedInset': 0,
                            '--Textarea-focusedThickness': 0,
                            '--Textarea-paddingBlock':0,
							'--Textarea-paddingInline':0,
                            '--Textarea-focusedHighlight': 'black',
							width:'auto',
							height:'82px',
                            mx:2,
							minHeight:'0px',
							borderRadius:'0px',
							border:'1px solid black',
							fontSize:'18px',
							fontWeight:'600',
                            lineHeight: 1
						}}
					/>
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'flex-end',
                            my:1
                        }}
                    >
                        <Button
                            onClick={handleSave}
                            sx={{
                                display:'flex', 
								flexDirection:'column',
                                height:'80px',
                                width:'120px',
                                mr:2,
                                backgroundColor:'#c6c3ff',
								color:'black',
                                borderRadius:'0px',
                                borderTop:'1px solid white',
								borderRight:'1px solid #424142',
								borderBottom:'1px solid #424142',
								borderLeft:'1px solid white',
								paddingInline:0,
                                '&:hover': {
                                    backgroundColor: '#a8a5e6',
                                }
                            }}
                        >
                            <AddIcon 
								sx={{
                                    color:'black',
                                    height:'50px',
                                    width:'50px'
								}}
							/>
							<Typography 
								sx={{
									fontSize:'20px'
								}}
							>
								Save
							</Typography>
                        </Button>
                        <Button
                            onClick={handleClose}
                            sx={{
                                display:'flex', 
								flexDirection:'column',
                                height:'80px',
                                width:'120px',
                                mr:2,
                                backgroundColor:'#c6c3ff',
								color:'black',
                                borderRadius:'0px',
                                borderTop:'1px solid white',
								borderRight:'1px solid #424142',
								borderBottom:'1px solid #424142',
								borderLeft:'1px solid white',
								paddingInline:0,
                                '&:hover': {
                                    backgroundColor: '#a8a5e6',
                                }
                            }}
                        >
                            <MeetingRoomIcon 
								sx={{
                                    color:'black',
                                    height:'50px',
                                    width:'50px'
								}}
							/>
							<Typography 
								sx={{
									fontSize:'20px'
								}}
							>
								Close
							</Typography>
                        </Button>
                    </Box>
                </Box>                         
            </Box>
		</CssVarsProvider>
	);
}