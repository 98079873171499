import * as React from 'react';
import { useNavigate } from 'react-router-dom';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.tsx';
import AdditionalInfoWnd from './Additional_Info_wnd.tsx';

import URL from '../../../../../../URL.js';

export default function Operator_Registration() {
	const [data, setData] = React.useState<{id: number, icao: string, iata: string, name: string, staOffice: string, address: string, phone: string, fax: string, email: string, contactOne: string, contactTwo: string, web: string, remarks: string, active: string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id: number, icao: string, iata: string, name: string, staOffice: string, address: string, phone: string, fax: string, email: string, contactOne: string, contactTwo: string, web: string, remarks: string, active: string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({});
	const [filters, setFilters] = React.useState({id:0, icao:'', iata:'', name:'', staOffice:'', address:'', phone:'', fax:'', email:'', contactOne:'', contactTwo:'', web:'', remarks:'', active:'Y'});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [showEditor, setShowEditor] = React.useState (true);
	const [operators, setOperators] = React.useState<{ icao: string, name:string }[]>([]);
	const [staOffice, setStaOffice] = React.useState<{code:string}[]>([]);
	const [error, setError] = React.useState({serverError:false});
	const navigate = useNavigate();

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'16px'};

	//Запрос данных для таблицы
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchData();
	}, []);

	//Перезагрузка таблицы 
	const reloadTable = async() => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			errors(response.status);
		}
	}

	//Запрос для получения списка операторов
	React.useEffect(() => {
		const fetchOperators = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setOperators(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchOperators();
	}, []);

	//Запрос для получения списка станций
	React.useEffect(() => {
		const fetchStaOffice = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations?$select=code`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setStaOffice(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchStaOffice();
	}, []);
	
	//Выбор строки
	const handleRowSelection = (row) => {
        setSelectedRow(row);
    };

	//Изменение чекбокса Activate
	const handleCheckboxChange = (event) => {
		const activateValue = event.target.checked ? 'Y' : 'N';
		handleChange('active', activateValue);
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
        if (filters.id) {filtered = filtered.filter(row => row.id?.toString().toLowerCase().includes(filters.id.toString()))}
		if (filters.icao) {filtered = filtered.filter(row => row.icao?.toLowerCase().includes(filters.icao.toLowerCase()))}
		if (filters.iata) {filtered = filtered.filter(row => row.iata?.toLowerCase().includes(filters.iata.toLowerCase()))}
		if (filters.name) {filtered = filtered.filter(row => row.name?.toLowerCase().includes(filters.name.toLowerCase()))}
		if (filters.staOffice) {filtered = filtered.filter(row => row.staOffice?.toLowerCase().includes(filters.staOffice.toLowerCase()))}
		if (filters.address) {filtered = filtered.filter(row => row.address?.toLowerCase().includes(filters.address.toLowerCase()))}
		if (filters.phone) {filtered = filtered.filter(row => row.phone?.toLowerCase().includes(filters.phone.toLowerCase()))}
		if (filters.fax) {filtered = filtered.filter(row => row.fax?.toLowerCase().includes(filters.fax.toLowerCase()))}
		if (filters.email) {filtered = filtered.filter(row => row.email?.toLowerCase().includes(filters.email.toLowerCase()))}
		if (filters.contactOne) {filtered = filtered.filter(row => row.contactOne?.toLowerCase().includes(filters.contactOne.toLowerCase()))}
		if (filters.contactTwo) {filtered = filtered.filter(row => row.contactTwo?.toLowerCase().includes(filters.contactTwo.toLowerCase()))}
		if (filters.web) {filtered = filtered.filter(row => row.web?.toLowerCase().includes(filters.web.toLowerCase()))}
		if (filters.remarks) {filtered = filtered.filter(row => row.remarks?.toLowerCase().includes(filters.remarks.toLowerCase()))}
		if (filters.active) { if(filters.active == 'Y') {filtered = filtered.filter(row => row.active == 'Y')}}
		switch(sort.lable){
			case 'id':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.id - b.id : b.id - a.id);
			break;
			case 'icao':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.icao.localeCompare(b.icao) : b.icao.localeCompare(a.icao));
			break;
			case 'iata':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.iata.localeCompare(b.iata) : b.iata.localeCompare(a.iata));
			break;
			case 'name':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
			break;
			case 'staOffice':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.staOffice.localeCompare(b.staOffice) : b.staOffice.localeCompare(a.staOffice));
			break;
			case 'address':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.address.localeCompare(b.address) : b.address.localeCompare(a.address));
			break;
			case 'phone':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.phone.localeCompare(b.phone) : b.phone.localeCompare(a.phone));
			break;
			case 'fax':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.fax.localeCompare(b.fax) : b.fax.localeCompare(a.fax));
			break;
			case 'email':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email));
			break;
			case 'contactOne':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.contactOne.localeCompare(b.contactOne) : b.contactOne.localeCompare(a.contactOne));
			break;
			case 'contactTwo':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.contactTwo.localeCompare(b.contactTwo) : b.contactTwo.localeCompare(a.contactTwo));
			break;
			case 'web':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.web.localeCompare(b.web) : b.web.localeCompare(a.web));
			break;
			case 'remarks':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.remarks.localeCompare(b.remarks) : b.remarks.localeCompare(a.remarks));
			break;
			case 'active':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.active.localeCompare(b.active) : b.active.localeCompare(a.active));
			break;
		}
        setFilteredData(filtered);
    }, [data, filters, sort]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, icao:'', iata:'', name:'', staOffice:'', address:'', phone:'', fax:'', email:'', contactOne:'', contactTwo:'', web:'', remarks:'', active:'Y'});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение сортировки
	const handleSort = (property, value) => {
		setSort({lable:property, order:value})
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations/Xlsx()?$activate = '${filters.active}'`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Operator_Registration.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			} else {
				throw new Error("Blob is undefined");
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				setError(prevState => ({ ...prevState, serverError: true }));
				navigate('/SignIn');
				console.error(403);
			break;
			case 404:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};

	// Функция для сброса ошибки через 3 секунды
	React.useEffect(() => {
        const resetStatus = () => {
            setTimeout(() => {
				setError(prevState => ({ ...prevState, serverError: false }));
            }, 3000);
        };

        if (error.serverError) {
            resetStatus();
        }
    }, [error]);

	return (
		<React.Fragment>
			{error.serverError && (
				<Box
					sx={{
						position: 'fixed',
						top: '60px',
						right: '20px',
						display: 'flex',
						alignItems: 'center',
						color: 'red',
						backgroundColor:'#FCE4E4',
						px:1,
						borderRadius:'7px',
						zIndex: 9999,

					}}
				>
					<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
					<Typography sx={{ mt: 0.3, color: 'red' }}>
						Server error!
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '70% 30%' : '1rf',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px:1,
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '85dvh',
						position:'relative'
					}}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0
						}}
					>
						<Tooltip title='Create Excel Report'>
							<Button
								variant='plain'
								onClick={createExcel}
								sx={{
									cursor:'pointer',
									width:'40px'
								}}
							>
								<img 
									width="40px" 
									src="images/excel.svg" 
									alt="" 
								/>
							</Button>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<CachedIcon
								sx={{width:'40px', height:'40px', cursor: 'pointer'}}
								onClick={refreshFilters}
							/>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<ArrowForwardIcon
								sx={{	
									cursor: 'pointer',
									width:'40px', 
									height:'40px',
									transition: '0.2s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
								onClick={toggleEditore} 
							/>
						</Tooltip>
					</Box>	
					<Typography 
						sx={{ 
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif'
						}}
					>
						Operator Registration List:
					</Typography>			
					<Box
						className="SearchAndFilters-tabletUp"
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 1.5,
							alignItems:'flex-end',
							justifyContent:'center'
						}}
					>
						<Checkbox 
							variant="outlined"
							color="neutral" 
							label="Active Only"
							checked={filters.active == 'Y' ? true:false}
							onChange={handleCheckboxChange}
							sx={{
								mb:1,
								'& .css-r4cpcz-JoyCheckbox-checkbox': {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
								'& .css-n110n' : {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
							}}
						/>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={{
							display:'initial',
							width: '100%',
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: '730px',
							border: '1px solid black',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ID
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('groupName', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'groupName' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ICAO_Code
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('sta', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'sta' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											IATA_Code
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Name
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											STA_Main_Office
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Address
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Phone
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Fax
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											E-Mail
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Contact_1
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Contact_2
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											WEB
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Remarks
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Active
										</Typography>
									</th>
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={{
													width:'60px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.icao}
												onChange={(event) => handleChange('icao', event.target.value)}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.iata}
												onChange={(event) => handleChange('iata', event.target.value)}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.name}
												onChange={(event) => handleChange('name', event.target.value)}
												sx={{
													width:'122px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.staOffice}
												onChange={(event) => handleChange('staOffice', event.target.value)}
												sx={{
													width:'160px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.address}
												onChange={(event) => handleChange('address', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.phone}
												onChange={(event) => handleChange('phone', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.fax}
												onChange={(event) => handleChange('fax', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.email}
												onChange={(event) => handleChange('email', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.contactOne}
												onChange={(event) => handleChange('contactOne', event.target.value)}
												sx={{
													width:'110px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.contactTwo}
												onChange={(event) => handleChange('contactTwo', event.target.value)}
												sx={{
													width:'110px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.web}
												onChange={(event) => handleChange('web', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow === row ? '#00cfd6' : 'white',
											color: selectedRow === row ? 'white' : 'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow === row ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.icao}</td>
                                		<td style={rowStyle}>{row.iata}</td>
                                		<td style={rowStyle}>{row.name}</td>
										<td style={rowStyle}>{row.staOffice}</td>
										<td style={rowStyle}>{row.address}</td>
										<td style={rowStyle}>{row.phone}</td>
										<td style={rowStyle}>{row.fax}</td>
										<td style={rowStyle}>{row.email}</td>
										<td style={rowStyle}>{row.contactOne}</td>
										<td style={rowStyle}>{row.contactTwo}</td>
										<td style={rowStyle}>{row.web}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.active}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={{
							px:1,
							pb: 1,
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minWidth: 0,
							height: '85dvh',
							gap: 1,
						}}
					>
						<AddEditeForm
							selectedRow={selectedRow} 
							reloadTable={reloadTable}
							setError={setError}
							operators={operators}
							staOffice={staOffice}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}