import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

import URL from '../../../../../../URL.js';

export default function AdditionalInfoWnd({setOpen, selectedOperator, selectedSta, criteria}) {
    const [data, setData] = React.useState({tableName:'', fieldName:'', fieldValue:'',item:{icao:'', name:'', code:'', country:'', remarks:'', activate:''}});

	const handleClose = () => {
		setOpen(false);
	};

	let xcenter = (window.innerWidth/2)-300;
	let ycenter = (window.innerHeight/2)-300;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

    React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/AdditionalInfo()?code='${selectedSta}'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result[0]);
			} else {
				//errors(response.status);
			}
		};
		fetchData();
	}, []);

	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'600px',
						width:'600px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
                        opacity: 0.85
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 570px'
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								startDecorator={<InfoIcon sx={{height:'20px', color:'white'}}/>}
								sx={{
									fontSize:'15px',
									fontWeight:'600',
									color:'white',
								}}
							>
								Additional Info
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box
							sx={{
								display:'grid',
								gridTemplateRows:'60px 445px',
								backgroundColor:'#d6d3ce'
							}}
						>
							<Box
								sx={{
									my:1,
									mx:2,
								}}
							>
								<Typography
									sx={{
										color:'#000084',
										fontSize:'14px',
										lineHeight:'1'
									}}
								>
									Selected Table: '{data.tableName}'; <br/>
									Search Field: '{data.fieldName}'; <br/>
									Search Criteria: '{data.fieldValue}'
								</Typography>
							</Box>
							
							<Box
								sx={{
									border:'1px solid black',
									mt:2,
									mx:2,
									p:1,
									bgcolor:'#52cbc6'
								}}
							>
								<Typography
									sx={{
										color:'black',
										fontSize:'15px',
										lineHeight:'1.2'
									}}
								>
									{data.item.code && (
										<>
											STA_Code: '{data.item.code}' <br/>
										</>
									)}
									{data.item.name && (
										<>
											STA_Name: '{data.item.name}' <br/>
										</>
									)}
									{data.item.country && (
										<>
											STA_Country: '{data.item.country}' <br/>
										</>
									)}
									{data.item.remarks && (
										<>
											Remarks: '{data.item.remarks}' <br/>
										</>
									)}
									{data.item.activate && (
										<>
											Activate: '{data.item.activate}' <br/>
										</>
									)}
								</Typography>
							</Box>
							<Box
								sx={{
									display:'flex',
									justifyContent:'flex-end'								
								}}
							>
								<Button
									onClick={handleClose}
									sx={{
										display:'flex', 
										flexDirection:'column',
										backgroundColor:'#c0bdfe',
										borderRadius:'0px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										borderRight:'2px solid black',
										borderBottom:'2px solid black',
										height:'48px',
										minHeight:'0px',
										width:'78px',
										my:1,
										mx:1,
										'&:hover': {
											backgroundColor: '#a8a5e6',
										}
									}}
								>
									<MeetingRoomIcon
										sx={{
											color:'black',
											height:'30px',
											width:'30px'
										}}
									/>
									<Typography
										sx={{
											fontSize:'12px'
										}}
									>
										Close
									</Typography>
								</Button>
							</Box>
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}