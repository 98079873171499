import * as React from 'react';

import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';

import AMPCreating from './AMP_Creating_window.tsx';

import URL from '../../../../../URL.js'

export default function AMP(data) {
	const [selectedOperator, setSelectedOperator] = React.useState({id:'', acReg:'', acSN:'', acFamily:'', acType:'', operatorCodeICAO:'', operatorName:'', acManufacturer:'', staOperatorBaseLocation:''});
	const [selectedACFamily, setSelectedACFamily] = React.useState({acFamily:'', acManufacturer:''});
	const [openAMPCreating, setOpenAMPCreating] = React.useState(false);
	const [aircrafts, setAircrafts] = React.useState([{acFamily:'',acManufacturer:''}]);
	const [AMPExist, setAMPExist] = React.useState(true)

    const handleOperatorSelection = (operator) => {
        setSelectedOperator(operator);
		setAircrafts(data.data.filter(aircraft => aircraft.acFamily === operator.acFamily).reduce((acc, aircraft) => {
			if (!acc.some(item => item.acFamily === aircraft.acFamily)) {
				acc.push(aircraft);
			}
			return acc;
		}, []))
    }
	const handleACFamilySelection = (aircraft) => {
		setSelectedACFamily(aircraft);
		isAMPexist(aircraft);
	}

	const handleOpenAMPCreating = React.useCallback(() => {
		setOpenAMPCreating(true);
	}, []);
	
	React.useEffect(() => {
		if (!openAMPCreating) {
		  handleACFamilySelection(selectedACFamily);
		}
	}, [openAMPCreating]);

	const operators = data.data.reduce((acc, aircraft) => {
		if (!acc.some(item => item.operatorCodeICAO === aircraft.operatorCodeICAO)) {
			acc.push(aircraft);
		}
		return acc;
	}, []);

	const isAMPexist = (aircraft) => {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Amp?$filter=acFamily eq '${aircraft.acFamily}'`,
			{
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				},
			}
		)
		.then(response => response.json())
		.then((data) => {
			if (data.value.length != 0){
				setAMPExist(true)
			} else {
				setAMPExist(false)
			}
		})
		.catch(error => console.error('Error:', error));
	}

	return (
		<CssVarsProvider disableTransitionOnChange>
			<>
				{openAMPCreating &&(
					<AMPCreating setOpen={setOpenAMPCreating} AC={selectedACFamily} operator={selectedOperator}/>
				)}
				<Box
					sx={{
						display: 'grid',
						gridTemplateRows: '30% 70%',
						height: '100%',
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateRows: '0fr 1fr',
						}}
					>        
						<Typography
							sx={{
								ml:2,
								fontSize:'24px',
								fontWeight:'500',
								color:'black'
							}}
						>
							AMP - Select Operator Name:
						</Typography>
						<Box
							sx={{
								overflow:'auto',
								mx:2,
								backgroundColor:'white'
							}}
						>
							<Table 
								aria-label="table variants" 
								variant='plain' 
								color='neutral'
								size='md'
								hoverRow
								noWrap
								borderAxis='none'
								sx={{
									borderRadius:'8px',
									tableLayout: 'auto',
									maxHeight:'110px',
									"--TableCell-height": "1px",
									"--TableCell-paddingY": "0px",
									width:'100%',
								}}
							>
								<tbody>
									{operators.map((operator) => (
										<tr 
											onClick={() => handleOperatorSelection(operator)} 
											style={{ 
												backgroundColor: selectedOperator === operator ? '#08246b' : 'white',
												color: selectedOperator === operator ? 'white' : '#000076',
												fontSize:'20px',
												fontWeight:'bold'
											}}
										>
											<td style={{width:'auto'}}>{operator.operatorCodeICAO}</td>
											<td>{operator.operatorName}</td>
											<td></td>
										</tr>
									))}
								</tbody>
							</Table>	
						</Box>                              
					</Box>
					<Box
						sx={{
							display: 'grid',
							gridTemplateRows: '0fr 5fr',
							mt:2
						}}
					>
						<Typography
							sx={{
								ml:2,
								fontSize:'24px',
								fontWeight:'500',
								color:'black'
							}}
						>
							AMP for Selected - Operator, Select - AC Family:
						</Typography> 
						<Box
							sx={{
								display:'grid',
								gridTemplateColumns:'auto 135px',
								overflow:'auto',
								maxHight:'100dvh',
								mx:2,
								mb:2
							}}
						>
							<Box
								sx={{
									backgroundColor:'white',
									mr:2
								}}
							>
								<Table 
									variant='plain' 
									color='neutral'
									size='md'
									hoverRow
									noWrap
									borderAxis='none'
									sx={{
										borderRadius:'8px',
										tableLayout: 'auto',
										maxHeight:'110px',
										"--TableCell-height": "1px",
										"--TableCell-paddingY": "0px",
										width:'auto',
										maxWidth:'95%',
									}}
								>
									<tbody>
										{selectedOperator && aircrafts.map((aircraft) => (
											<tr 
												onClick={() => handleACFamilySelection(aircraft)} 
												style={{ 
													backgroundColor: selectedACFamily === aircraft ? '#08246b' : 'white',
													color: selectedACFamily === aircraft ? 'white' : '#000076',
													fontSize:'20px',
													fontWeight:'bold'
												}}
											>
												<td>{aircraft.acFamily}</td>
												<td>{aircraft.acManufacturer}</td>
											</tr>
										))}
										{selectedOperator.id == '' && (
											<tr 
												style={{
													fontSize:'20px', 
													fontWeight:'bold', 
													color: '#000076'
												}}
											>
												<td>No Selection! Select Name of Operator!</td>
											</tr>)}
									</tbody>
								</Table>	
							</Box>

							{selectedACFamily.acFamily ? (
								<>
								{AMPExist ? (
									<Button
										variant="outlined"
										color="neutral"
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											height:'40px'
										}}
									>
										Open
									</Button>
								) : (
									<Button
										variant="outlined"
										color="neutral"
										onClick={handleOpenAMPCreating}
										sx={{
											borderRadius: '0px',
											fontSize: '18px',
											backgroundColor:'#dedff7',
											borderRight:'2px solid #424142',
											borderBottom:'2px solid #424142',
											borderLeft:'2px solid white',
											borderTop:'2px solid white',
											height:'40px'
										}}
									>
										Create
									</Button>
								)}
								</>
							):(
								<Box/>
							)}
						</Box>                                        
					</Box>
				</Box>
			</>
		</CssVarsProvider>
	);
}
