import * as React from 'react';
import { useNavigate } from 'react-router-dom';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.tsx';

import URL from '../../../../../../URL.js';

export default function STA_Registration() {
	const [data, setData] = React.useState<{id:number, icao:string, iata:string, name:string, country:string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, icao:string, iata:string, name:string, country:string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({});
	const [filters, setFilters] = React.useState({id:0, icao:'', iata:'', name:'', country:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [showEditor, setShowEditor] = React.useState (true);
	const [error, setError] = React.useState({serverError:false});
	const navigate = useNavigate();

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

	//Запрос данных для таблицы
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchData();
	}, []);

	//Перезагрузка таблицы 
	const reloadTable = async() => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			errors(response.status);
		}
	}
	
	//Выбор строки
	const handleRowSelection = (row) => {
        setSelectedRow(row);
    };

	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
        if (filters.id) {filtered = filtered.filter(row => row.id?.toString().toLowerCase().includes(filters.id.toString()))}
		if (filters.icao) {filtered = filtered.filter(row => row.icao?.toLowerCase().includes(filters.icao.toLowerCase()))}
		if (filters.iata) {filtered = filtered.filter(row => row.iata?.toLowerCase().includes(filters.iata.toLowerCase()))}
		if (filters.name) {filtered = filtered.filter(row => row.name?.toLowerCase().includes(filters.name.toLowerCase()))}
		if (filters.country) {filtered = filtered.filter(row => row.country == filters.country)}
		switch(sort.lable){
			case 'id':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.id - b.id : b.id - a.id);
			break;
			case 'icao':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.icao.localeCompare(b.icao) : b.icao.localeCompare(a.icao));
			break;
			case 'iata':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.iata.localeCompare(b.iata) : b.iata.localeCompare(a.iata));
			break;
			case 'name':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
			break;
			case 'country':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.country.localeCompare(b.country) : b.country.localeCompare(a.country));
			break;
		}
        setFilteredData(filtered);
    }, [data, filters, sort]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, icao:'', iata:'', name:'', country:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение сортировки
	const handleSort = (property, value) => {
		setSort({lable:property, order:value})
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines/Xlsx()`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Airline_Code.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			} else {
				throw new Error("Blob is undefined");
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				setError(prevState => ({ ...prevState, serverError: true }));
				navigate('/SignIn');
				console.error(403);
			break;
			case 404:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};

	// Функция для сброса ошибки через 3 секунды
	React.useEffect(() => {
        const resetStatus = () => {
            setTimeout(() => {
				setError(prevState => ({ ...prevState, serverError: false }));
            }, 3000);
        };

        if (error.serverError) {
            resetStatus();
        }
    }, [error]);

	return (
		<React.Fragment>
			{error.serverError && (
				<Box
					sx={{
						position: 'fixed',
						top: '60px',
						right: '20px',
						display: 'flex',
						alignItems: 'center',
						color: 'red',
						backgroundColor:'#FCE4E4',
						px:1,
						borderRadius:'7px',
						zIndex: 9999,

					}}
				>
					<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
					<Typography sx={{ mt: 0.3, color: 'red' }}>
						Server error!
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '70% 30%' : '1rf',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px:1,
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '85dvh',
						position:'relative'
					}}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0
						}}
					>
						<Tooltip title='Create Excel Report'>
							<Button
								variant='plain'
								onClick={createExcel}
								sx={{
									cursor:'pointer',
									width:'40px'
								}}
							>
								<img 
									width="40px" 
									src="images/excel.svg" 
									alt="" 
								/>
							</Button>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<CachedIcon
								sx={{width:'40px', height:'40px', cursor: 'pointer'}}
								onClick={refreshFilters}
							/>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<ArrowForwardIcon
								sx={{	
									cursor: 'pointer',
									width:'40px', 
									height:'40px',
									transition: '0.2s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
								onClick={toggleEditore} 
							/>
						</Tooltip>
					</Box>	
					<Typography 
						sx={{ 
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif'
						}}
					>
						Airline Code Registration List:
					</Typography>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							mt:'20px'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={{
							display:'initial',
							width: '100%',
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: '735px',
							border: '1px solid black',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ID
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('icao', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'icao' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ICAO_Code
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('iata', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'iata' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											IATA_Code
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('name', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'name' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Airline_Name
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('country', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'country' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Country
										</Typography>
									</th>
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={{
													width:'60px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.icao}
												onChange={(event) => handleChange('icao', event.target.value)}
												sx={{
													width:'120px',
													minWidth:'0px',
													height:'100%',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.iata}
												onChange={(event) => handleChange('iata', event.target.value)}
												sx={{
													width:'115px',
													minWidth:'0px',
													height:'100%',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.name}
												onChange={(event) => handleChange('name', event.target.value)}
												sx={{
													minWidth:'0px',
													height:'100%',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.country))].filter(row => row).map(country => ({ label: country}))}
												sx={{borderRadius:0,pl:0,width:'175px'}}
												onChange={(event, value) => handleChange('country', value ? value.label : '')}
												value={filters.country === '' ? null : { label: filters.country}}
											/>
										</th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow === row ? '#00cfd6' : 'white',
											color: selectedRow === row ? 'white' : 'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow === row ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.icao}</td>
                                		<td style={rowStyle}>{row.iata}</td>
                                		<td style={rowStyle}>{row.name}</td>
										<td style={rowStyle}>{row.country}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={{
							px:1,
							pb: 1,
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minWidth: 0,
							height: '85dvh',
							gap: 1,
						}}
					>
						<AddEditeForm
							selectedRow={selectedRow} 
							reloadTable={reloadTable}
							setError={setError}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}