import * as React from 'react';
import { useNavigate } from 'react-router-dom';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.tsx';
import AdditionalInfoWnd from './Additional_Info_wnd.tsx';

import URL from '../../../../../../URL.js';

export default function User_Operator_Fleet() {
	const [data, setData] = React.useState<{id:number, idUser:number, user:string, userName:string, icao:string, operatorName:string, aircraftFamily:string, activate:string, remarks:string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, idUser:number, user:string, userName:string, icao:string, operatorName:string, aircraftFamily:string, activate:string, remarks:string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({});
	const [filters, setFilters] = React.useState({id:0, idUser:0, user:'', userName:'', icao:'', operatorName:'', aircraftFamily:'', activate:'', remarks:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [showEditor, setShowEditor] = React.useState (true);
	const [openAdditionalInfo, setOpenAdditionalInfo] = React.useState(false);
	const [criteriaAdditionalWnd, setCriteriaAdditionalWnd] = React.useState('');
	const [users, setUsers] = React.useState<{ login: string }[]>([]);
	const [operators, setOperators] = React.useState<{ icao: string, name:string }[]>([]);
	const [aircrafts, setAircrafts] = React.useState<{ aircraftFamily: string }[]>([]);
	const [error, setError] = React.useState({serverError:false});
	const navigate = useNavigate();

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px',  maxWidth:'240px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

	//Запрос данных для таблицы
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets/View()`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				errors(response.status);
			}
		};
		fetchData();
	}, []);

	//Перезагрузка таблицы 
	const reloadTable = async() => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets/View()`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result);
		} else {
			errors(response.status);
		}
	}

	//Запрос для получения списка Users
	React.useEffect(() => {
		const fetchUsers = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Users?$select=id,login,name`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setUsers(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchUsers();
	}, []);

	//Запрос для получения списка Operators
	React.useEffect(() => {
		const fetchOperators = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations?$apply=groupby((id,icao,name))`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setOperators(result);
			} else {
				errors(response.status);
			}
		};
		fetchOperators();
	}, []);

	//Запрос для получения списка Aircrafts
	React.useEffect(() => {
		const fetchAircrafts = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets?$apply=groupby((aircraftfamily))`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setAircrafts(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchAircrafts();
	}, []);
	
	//Выбор строки
	const handleRowSelection = (row) => {
        setSelectedRow(row);
    };
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
        if (filters.id) {filtered = filtered.filter(row => row.id?.toString().toLowerCase().includes(filters.id.toString()))}
		if (filters.idUser) {filtered = filtered.filter(row => row.idUser?.toString().toLowerCase().includes(filters.idUser.toString()))}
		if (filters.user) {filtered = filtered.filter(row => row.user == filters.user)}
		if (filters.userName) {filtered = filtered.filter(row => row.userName?.toLowerCase().includes(filters.userName.toLowerCase()))}
		if (filters.icao) {filtered = filtered.filter(row => row.icao == filters.icao)}
		if (filters.operatorName) {filtered = filtered.filter(row => row.operatorName == filters.operatorName)}
		if (filters.aircraftFamily) {filtered = filtered.filter(row => row.aircraftFamily == filters.aircraftFamily)}
		if (filters.remarks) {filtered = filtered.filter(row => row.remarks?.toLowerCase().includes(filters.remarks.toLowerCase()))}
		switch(sort.lable){
			case 'id':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.id - b.id : b.id - a.id);
			break;
			case 'idUser':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.idUser - b.idUser : b.idUser - a.idUser);
			break;
			case 'user':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.user.localeCompare(b.user) : b.user.localeCompare(a.user));
			break;
			case 'userName':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.userName.localeCompare(b.userName) : b.userName.localeCompare(a.userName));
			break;
			case 'icao':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.icao.localeCompare(b.icao) : b.icao.localeCompare(a.icao));
			break;
			case 'operatorName':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.operatorName.localeCompare(b.operatorName) : b.operatorName.localeCompare(a.operatorName));
			break;
			case 'aircraftFamily':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.aircraftFamily.localeCompare(b.aircraftFamily) : b.aircraftFamily.localeCompare(a.aircraftFamily));
			break;
			case 'remarks':
				filtered = filtered.sort((a, b) => sort.order === 'asc' ? a.remarks.localeCompare(b.remarks) : b.remarks.localeCompare(a.remarks));
			break;
		}
        setFilteredData(filtered);
    }, [data, filters, sort]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, idUser:0, user:'', userName:'', icao:'', operatorName:'', aircraftFamily:'', activate:'', remarks:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение сортировки
	const handleSort = (property, value) => {
		setSort({lable:property, order:value})
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//Обработка нажатия на кнопку F1 при фокусе на фильтр
	const handleUserAdditionalInfo = (event) => {
		if (event.code === 'F1') {
			event.preventDefault(); 
			if (filters.user) {
				setOpenAdditionalInfo(true);
				setCriteriaAdditionalWnd('user');
			}
		}
	};

	const handleOperatorAdditionalInfo = (event) => {
		if (event.code === 'F1') {
			event.preventDefault(); 
			if (filters.user) {
				setOpenAdditionalInfo(true);
				setCriteriaAdditionalWnd('operator');
			}
		}
	};
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets/Xlsx()?icao='${filters.operatorName}',user='${filters.user}',family='${filters.aircraftFamily}')`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UsersFleet.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			} else {
				throw new Error("Blob is undefined");
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				setError(prevState => ({ ...prevState, serverError: true }));
				navigate('/SignIn');
				console.error(403);
			break;
			case 404:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};

	// Функция для сброса ошибки через 3 секунды
	React.useEffect(() => {
        const resetStatus = () => {
            setTimeout(() => {
				setError(prevState => ({ ...prevState, serverError: false }));
            }, 3000);
        };

        if (error.serverError) {
            resetStatus();
        }
    }, [error]);

	return (
		<React.Fragment>
			{openAdditionalInfo &&(
				<AdditionalInfoWnd setOpen={setOpenAdditionalInfo} selectedUser={filters.user} selectedOperator={filters.icao} criteria={criteriaAdditionalWnd}/>
			)}
			{error.serverError && (
				<Box
					sx={{
						position: 'fixed',
						top: '60px',
						right: '20px',
						display: 'flex',
						alignItems: 'center',
						color: 'red',
						backgroundColor:'#FCE4E4',
						px:1,
						borderRadius:'7px',
						zIndex: 9999,

					}}
				>
					<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
					<Typography sx={{ mt: 0.3, color: 'red' }}>
						Server error!
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '70% 30%' : '1rf',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px:1,
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '85dvh',
						position:'relative'
					}}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0
						}}
					>
						<Tooltip title='Create Excel Report'>
							<Button
								variant='plain'
								onClick={createExcel}
								sx={{
									cursor:'pointer',
									width:'40px'
								}}
							>
								<img 
									width="40px" 
									src="images/excel.svg" 
									alt="" 
								/>
							</Button>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<CachedIcon
								sx={{width:'40px', height:'40px', cursor: 'pointer'}}
								onClick={refreshFilters}
							/>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<ArrowForwardIcon
								sx={{	
									cursor: 'pointer',
									width:'40px', 
									height:'40px',
									transition: '0.2s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
								onClick={toggleEditore} 
							/>
						</Tooltip>
					</Box>	
					<Typography 
						sx={{ 
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif'
						}}
					>
						Security Groups Registration List:
					</Typography>			
					<Box
						className="SearchAndFilters-tabletUp"
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 1.5,
							alignItems:'flex-end'
						}}
					>
						<FormControl>
							<FormLabel sx={lableStyle}> 
								User ID:
							</FormLabel>
							<Autocomplete
								options={[...new Set(data.map(data => data.user))].map(user => ({ label: user }))}
								sx={{...inputStyle, backgroundColor:'#c6ffff'}}
								onChange={(event, value) => handleChange('user', value ? value.label : '')}
								onKeyDown={handleUserAdditionalInfo}
								value={filters.user === '' ? null : { label: filters.user }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}> 
								Operator:
							</FormLabel>
							<Autocomplete
								options={[...new Set(data.map(data => data.icao))].map(icao => ({ label: icao }))}
								sx={{...inputStyle, backgroundColor:'#c6ffff'}}
								onChange={(event, value) => handleChange('icao', value ? value.label : '')}
								onKeyDown={handleOperatorAdditionalInfo}
								value={filters.icao === '' ? null : { label: filters.icao }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}> 
								Aircraft Family:
							</FormLabel>
							<Autocomplete
								options={[...new Set(data.map(data => data.aircraftFamily))].map(aircraftFamily => ({ label: aircraftFamily }))}
								sx={inputStyle}
								onChange={(event, value) => handleChange('aircraftFamily', value ? value.label : '')}
								value={filters.aircraftFamily === '' ? null : { label: filters.aircraftFamily }}
							/>
						</FormControl>
						<Checkbox 
							variant="outlined"
							color="neutral" 
							label="Active Only"
							defaultChecked 
							sx={{
								mb:1,
								'& .css-r4cpcz-JoyCheckbox-checkbox': {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
								'& .css-n110n' : {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
							}}
						/>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={{
							display:'initial',
							width: '100%',
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: '700px',
							border: '1px solid black',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ID
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('groupName', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'groupName' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ID_User
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('sta', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'sta' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											User_ID
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											User_Name
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											ICAO_Code
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Name
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Aircraft_Family
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Remarks
										</Typography>
									</th>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('description', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'description' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											Activate
										</Typography>
									</th>
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={{
													width:'60px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.idUser}
												onChange={(event) => handleChange('idUser', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={{
													width:'100px',
													minWidth:'0px',
													height:'20px',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.user))].map(user => ({ label: user }))}
												sx={{borderRadius:0,pl:0,width:'120px'}}
												onChange={(event, value) => handleChange('user', value ? value.label : '')}
												onKeyDown={handleUserAdditionalInfo}
												value={filters.user === '' ? null : { label: filters.user }}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.userName}
												onChange={(event) => handleChange('userName', event.target.value)}
												sx={{
													minWidth:'0px',
													height:'100%',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.icao))].map(icao => ({ label: icao }))}
												sx={{borderRadius:0,pl:0,width:'120px'}}
												onChange={(event, value) => handleChange('icao', value ? value.label : '')}
												onKeyDown={handleOperatorAdditionalInfo}
												value={filters.icao === '' ? null : { label: filters.icao }}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.operatorName))].map(operatorName=> ({ label: operatorName}))}
												sx={{borderRadius:0,pl:0,width:'180px'}}
												onChange={(event, value) => handleChange('operatorName', value ? value.label : '')}
												value={filters.operatorName === '' ? null : { label: filters.operatorName}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.aircraftFamily))].map(aircraftFamily=> ({ label: aircraftFamily}))}
												sx={{borderRadius:0,pl:0,width:'180px'}}
												onChange={(event, value) => handleChange('aircraftFamily', value ? value.label : '')}
												value={filters.aircraftFamily === '' ? null : { label: filters.aircraftFamily}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={{
													minWidth:'0px',
													height:'100%',
													p:0,
													borderRadius:'0px',
													m:0
												}}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow === row ? '#00cfd6' : 'white',
											color: selectedRow === row ? 'white' : 'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow === row ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.idUser}</td>
                                		<td style={rowStyle}>{row.user}</td>
                                		<td style={rowStyle}>{row.userName}</td>
										<td style={rowStyle}>{row.icao}</td>
										<td style={rowStyle}>{row.operatorName}</td>
										<td style={rowStyle}>{row.aircraftFamily}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.activate}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={{
							px:1,
							pb: 1,
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minWidth: 0,
							height: '85dvh',
							gap: 1,
						}}
					>
						<AddEditeForm
							selectedRow={selectedRow} 
							reloadTable={reloadTable}
							setError={setError}
							users={users}
							operators={operators}
							aircrafts={aircrafts}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}