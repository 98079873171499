import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';

//Компоненты 
import Engine_Type from './Engine_Type/Engine_Type.tsx';
import Engine_Family from './Engine_Family/Engine_Family.tsx'

export default function Aircraft_Type_Family() {
	return (
		<React.Fragment>
			<Box
				sx={{
					display:'grid',
					gridTemplateRows:'1fr 1fr',
					height:'85vh'
				}}
			>
				<Engine_Type/>
				<Engine_Family/>
			</Box>
		</React.Fragment>
	);
}
