import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';

import TaskIcon from '@mui/icons-material/Task';
import CloseIcon from '@mui/icons-material/Close';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';

import AMP_Error_Creating from './AMP_Error_Creating.tsx';
import AMP_Creating_Submiting from './AMP_Creating_Submiting.tsx';
import AMP_Successful_Creating from './AMP_Successful_Creating.tsx';

import URL from '../../../../../URL.js'

export default function AMP_Creating_window({ setOpen, AC, operator}) {
	const [openSubmiting, setOpenSubmiting] = React.useState(false);
	const [successfulSave, setSuccessfulSave] = React.useState(false);
	const [errorSave, setErrorSave] = React.useState(false);
	const [remarksValue, setRemarksValue] = React.useState('');

	const handleRemarksChange = (event) => {
		const newValue = event.target.value;

		// Проверяем, если длина текста больше 255 символов
		if (newValue.length <= 255) {
			setRemarksValue(newValue);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handlCreate = () =>{
		setOpenSubmiting(true)
	};

	let xcenter = (window.innerWidth/2)-442;
	let ycenter = (window.innerHeight/2)-185;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const AMPcreate = () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Amp`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					acFamily: AC.acFamily,
					operatorCodeIcao: AC.operatorCodeICAO,
					operatorName: AC.operatorName,
					remarks: remarksValue,
				})
			  }
		)
		.then((response) => {
			if (!response.ok){
				if (response.status === 500) {
					setErrorSave(true);
					throw new Error("Server error");
				}
			} else {
				setSuccessfulSave(true);
			}
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка сохранения AMP:', error));
	}


	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 1,
					overflow:'hidden'
				}}
       		/>
				{openSubmiting && (
					<AMP_Creating_Submiting 
						setOpenSubmiting={setOpenSubmiting} 
						AC={AC} 
						operator={operator}
						handleCreate={AMPcreate}
					/>
				)}
				{successfulSave && (
					<AMP_Successful_Creating handleCloseWindow={() => setOpen(false)} setSuccessfulSave={setSuccessfulSave} />
				)}
				{errorSave && (
					<AMP_Error_Creating handleCloseWindow={() => setOpen(false)} setErrorSave={setErrorSave} />
				)}
				<Sheet
					variant="plain"
					sx={{
						height:'370px',
						width:'885px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:2,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 340px'
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								startDecorator={<AirplanemodeActiveIcon sx={{height:'20px', color:'white'}}/>}
								sx={{
									fontSize:'15px',
									fontWeight:'600',
									color:'white',
								}}
							>
								AMP Creating
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box
							sx={{
								display:'grid',
								gridTemplateRows:'105px 170px',
								backgroundColor:'#d6d3ce'
							}}
						>
							<Box
								sx={{
									display:'grid',
									gridTemplateRows:'25px ',
									border:'1px solid black',
									my:1,
									mx:1,
									height:'95px',
								}}
							>
								<Typography
									sx={{
										ml:1.5,
										color:'#000084',
										fontWeight:'bold'
									}}
								>
									Selected Operator - AC Family:
								</Typography>
								<Box 
									sx={{
										display:'flex',
										flexDirection:'space-between'
									}}
								>
									<FormControl sx={{mx:1, mt:'6px'}}>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'12px',
												margin:'0px'
											}}
										>
											AC Family:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AC.acFamily}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'200px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												'& textarea':{
													textAlign:'center',
													height:'10px'
												}
											}}
										/>
									</FormControl>
									<FormControl sx={{mx:1, mt:'6px'}}>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'12px',
												margin:'0px'
											}}
										>
											Operator Code ICAO:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={operator.operatorCodeICAO}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'115px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												'& textarea':{
													textAlign:'center'
												}
											}}
										/>
									</FormControl>
									<FormControl sx={{ml:1, mt:'6px'}}>
										<FormLabel 
											sx={{
												fontSize:'12px',
												margin:'0px'
											}}
										>
											Operator Name:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={operator.operatorName}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'500px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
											}}
										/>
									</FormControl>
								</Box>
							</Box>
							
							<Box
								sx={{
									border:'1px solid black',
									mt:2,
									mx:1
								}}
							>
								<FormControl sx={{mx:1, mt:3}}>
									<FormLabel 
										sx={{
											fontSize:'12px',
											ml:1.5,
											my:0
										}}
									>
										Remarks:
									</FormLabel>
									<Textarea 
										maxRows={4} 
										variant='outlined' 
										value={remarksValue}
										onChange={handleRemarksChange}
										sx={{
											'--Textarea-paddingBlock':0,
											'--Textarea-paddingInline':0,
											'--Textarea-focusedThickness': '0',
											width:'830px',
											height:'105px',
											minHeight:'0px',
											borderRadius:'0px',
											border:'1px solid black',
											fontWeight:'bold',
											fontSize:'16px',
										}}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									display:'flex',
									justifyContent:'flex-end'								
								}}
							>
								<Button 
									onClick={handlCreate}
									sx={{
										display:'flex', 
										flexDirection:'column',
										backgroundColor:'#c0bdfe',
										borderRadius:'0px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										borderRight:'2px solid black',
										borderBottom:'2px solid black',
										height:'48px',
										minHeight:'0px',
										width:'78px',
										my:1,
										mx:1,
										'&:hover': {
											backgroundColor: '#a8a5e6',
										}
									}}
								>
									<TaskIcon 
										sx={{
											color:'black',
											height:'30px',
											width:'30px'
										}}
									/>
									<Typography 
										sx={{
											fontSize:'12px'
										}}
									>
										Create
									</Typography>
								</Button>

								<Button
									onClick={handleClose}
									sx={{
										display:'flex', 
										flexDirection:'column',
										backgroundColor:'#c0bdfe',
										borderRadius:'0px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										borderRight:'2px solid black',
										borderBottom:'2px solid black',
										height:'48px',
										minHeight:'0px',
										width:'78px',
										my:1,
										mx:1,
										'&:hover': {
											backgroundColor: '#a8a5e6',
										}
									}}
								>
									<MeetingRoomIcon
										sx={{
											color:'black',
											height:'30px',
											width:'30px'
										}}
									/>
									<Typography
										sx={{
											fontSize:'12px'
										}}
									>
										Close
									</Typography>
								</Button>
							</Box>
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}
