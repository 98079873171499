import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

export default function Welcome(props) {
	return (
		<Box
            sx={{
                position:'absolute',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                flexGrow: 1,
                height:'95.63%',
                width:'100%',
            }}
        >
            <Box
                sx={{
                    position:'absolute',
                    display:'flex',
                    flexDirection:'column',
                }}
            >
                <Typography 
                    sx={{ 
                        cursor:'default',
                        fontSize:'30px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif'
                    }}
                >
                    WELCOME TO ADMINISTRATION MODULE
                </Typography>
                <Typography 
                    sx={{ 
                        cursor:'default',
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        mb:2
                    }}
                >
                    Aircraft and Engines Family, Type, Model - Registration Section
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("AA")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    1: Aircraft Actual - (Aircraft's Identification base on Actual Operator Information)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("AR")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    2: Aircraft Registration - (Aircraft's Base Information: Model, SN, Line, IPC, etc.)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("AM")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    3: Aircraft Model (Aircraft's Model Base Information Registration)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("ATF")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    4: Aircraft Type-Family (Aircraft's Type-Family Base Information Registration)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("EM")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    5: Engine Model (Engine's Model Base Information Registration)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("ETF")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    6: Engine Type-Family (Engine's Type-Family Base Information Registration)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("M")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    7: Manufacturer (Manufacturer Base Information Registration: Code, Name, Address, etc.)
                </Typography>
                <Typography
                    onClick={() => {props.setSelectedTab("ATA")}}
                    sx={{ 
                        fontSize:'24px',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        cursor:'pointer',
                        '&:hover' : {
							color:'#5A9EA5'
						},
                    }}
                >
                    8: ATA (ATA Chapters Information)
                </Typography>
            </Box>
		</Box>
	);
}
