import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';

import TaskIcon from '@mui/icons-material/Task';
import CloseIcon from '@mui/icons-material/Close';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import AMP_Creating_Submiting from './AMP_Creating_Submiting.tsx';
import AMP_Successful_Creating from './AMP_Successful_Creating.tsx';

export default function AS_Submit_Opening_Initializing({setSubmitingOpening, selectedRow, setOpenInitializing}) {

	const handleClose = () => {
		setSubmitingOpening(false);
	};

	const handleOpenInitializing = () =>{
		setOpenInitializing(true);
		setSubmitingOpening(false);
	};

	let xcenter = (window.innerWidth/2)-250;
	let ycenter = (window.innerHeight/2)-115;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 1,
					overflow:'hidden'
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'230px',
						width:'500px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:2,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 150px 50px'
						}}
					>
						<Box
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
						>
							<Typography 
								sx={{
									fontSize:'15px',
									fontWeight:'600',
									color:'white',
                                    ml:1
								}}
							>
								Initializing
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
                        <Box
                            sx={{
                                display:'grid',
                                gridTemplateColumns:'80px 420px'
                            }}
                        >
                            <Box>
                                <HelpOutlineIcon 
                                    sx={{
                                        color:'black',
                                        width:'60px',
                                        height:'60px',
                                        ml:1,
                                        mt:2
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{mt:0.5, }}>
									The Aircraft Registration Number: {selectedRow.acReg}; <br/>
                                    Serial Number: {selectedRow.acsn} <br/>
                                    Does Not Initialize in the System ! <br/>
                                    <br/>
                                    Would you Like Initialize Aircraft to Select Maintenance Program (AMP)? 
								</Typography>
                            </Box>
                        </Box>
						<Box
							sx={{
								display:'flex',
								justifyContent:'flex-end',
								alignItems:'center',
								backgroundColor:'#d6d3ce'
							}}
						>
							<Button
								onClick={handleOpenInitializing}
								sx={{
									minHeight:'0px',
									minWidth:'0px',
									height:'30px',
									width:'100px',
									color:'black',
									background:'#d6d3ce',
									borderLeft:'1px solid white',
									borderTop:'1px solid white',
									borderRight:'1px solid black',
									borderBottom:'1px solid black',
									borderRadius:'0px',
									mx:1,
									'&:hover': {
										backgroundColor: '#bfbdba',
									}
								}}
							>
								Yes
							</Button>
							<Button
								onClick={handleClose}
								sx={{
									minHeight:'0px',
									minWidth:'0px',
									height:'30px',
									width:'100px',
									color:'black',
									background:'#d6d3ce',
									borderLeft:'1px solid black',
									borderTop:'1px solid black',
									borderRight:'2px solid black',
									borderBottom:'2px solid black',
									borderRadius:'0px',
									mx:1,
									'&:hover': {
										backgroundColor: '#bfbdba',
									}
								}}
							>
								No
							</Button>
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}