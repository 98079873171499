import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function AMP_Successful_Creating ({ handleCloseWindow, setSuccessfulSave}) {

	const handleClose = () => {
        handleCloseWindow();
        setSuccessfulSave(false);
	};

	let xcenter = (window.innerWidth/2)-200;
	let ycenter = (window.innerHeight/2)-100;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 3,
					overflow:'hidden'
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'170px',
						width:'400px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:4,
						left: position.x,
						top: position.y,
						cursor: isDragging ? 'grabbing' : 'grab',
					}}
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 90px 50px'
						}}
					>
						<Box
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
							}}
						>
							<Typography 
								sx={{
									fontSize:'17px',
									fontWeight:'600',
									color:'white',
                                    ml:1
								}}
							>
								Done
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box
							sx={{
								display:'grid',
								gridTemplateColumns:'80px 320px'
							}}
						>
							<Box>
								<ErrorOutlineIcon sx={{color:'black', width:'70px', height:'90px'}}/>
							</Box>
							<Box>
								<Typography 
                                    sx={{
                                        mt:4,
                                        fontSize:"20px",
                                    }}
                                >
									AMP Creation Successfully Done !
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display:'flex',
								justifyContent:'flex-end',
								alignItems:'center',
								backgroundColor:'#d6d3ce'
							}}
						>
							<Button
                                onClick={handleClose}
								sx={{
									minHeight:'0px',
									minWidth:'0px',
									height:'30px',
									width:'100px',
									color:'black',
                                    fontSize:'16px',
									background:'#d6d3ce',
									borderLeft:'1px solid white',
									borderTop:'1px solid white',
									borderRight:'1px solid black',
									borderBottom:'1px solid black',
									borderRadius:'0px',
									mx:1,
									'&:hover': {
										backgroundColor: '#bfbdba',
									}
								}}
							>
								OK
							</Button>
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}