import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import CloseIcon from '@mui/icons-material/Close';

import URL from '../../../../../../URL.js';

export default function AircraftsUtilites({setOpen, selectedMRO}) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px' }
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px' }
	const formControlStyle = { mb: 1 }
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'18px',color:'black'};
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

    const [tableData, setTableData] = React.useState<{id:number, staCode:string, staName:string, staCountry:string, capability:string}[]>([]);
	const [editedData, setEditedData] = React.useState({code:'', name:'', country:'', capability:''});
	const [editedMRO, seteditedMRO] = React.useState({code:selectedMRO.code})
	const [selectedRow, setSelectedRow] = React.useState({id:0, staCode:'', staName:'', staCountry:'', capability:''});
	const [mro, setMro] = React.useState<{id:number, code:string, sta: string, name:string}[]>([]);
	const [sta,setSta] = React.useState<{id:number, code:string, name:string, country:string, remarks:string}[]>([{id:0, code:'', name:'', country:'', remarks:''}]);

	React.useEffect(() => {
		seteditedMRO({code:selectedMRO.code});
	}, [selectedMRO]);

	React.useEffect(() => {
		setEditedData({
			code:selectedRow.staCode,
			name:selectedRow.staName,
			country:selectedRow.staCountry,
			capability:selectedRow.capability
		});
	}, [selectedRow]);
	
	const handleClose = () => {
		setOpen(false);
	};

	let xcenter = -(window.innerWidth/2);
	let ycenter = (window.innerHeight/2)-400;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

    React.useEffect(() => {
		const fetchMro = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/MroRegistrations?$select=id,code,sta,name`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setMro(result.value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchMro();
	}, []);

	React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/MroStaRegistrations/RegistrationList()?mroId=${mro.find(item => item.code === editedMRO.code)?.id}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setTableData(result);
			} else {
				//errors(response.status);
			}
		};
		fetchData();
	}, [editedMRO,mro]);

	const reloadTable = async() => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/MroStaRegistrations/RegistrationList()?mroId=${mro.find(item => item.code === editedMRO.code)?.id}`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setTableData(result);
		} else {
			//errors(response.status);
		}
	}

	React.useEffect(() => {
		const fetchSta = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setSta(result.value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchSta();
	}, []);

	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	const handleChangeMRO = (property, value) => {
		seteditedMRO({ ...editedMRO, [property]: value });
	};

	const handleRowSelection = (row) => {
        setSelectedRow(row);
    };

	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/MroStaRegistrations`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					mroId: mro.find(item => item.code === editedMRO.code)?.id,
					staId: sta.find(sta => sta.code === editedData.code)?.id,
					capability: editedData.capability,
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				//errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/MroStaRegistrations/${selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					mroId: mro.find(item => item.code === editedMRO.code)?.id,
					staId: sta.find(sta => sta.code === editedData.code)?.id,
					capability: editedData.capability,
				})
			  }
		)
		.then((response) => {
			if (!response.ok){
				//errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка обновления группы:', error));

	}

	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/MroStaRegistrations/${selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				//errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка удаления группы:', error));

	}


	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'600px',
						width:'800px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 100px 467px',
							bgcolor:'#d6d3ce'
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								sx={{
									fontSize:'15px',
									color:'white',
                                    ml:1
								}}
							>
								MRO STA Registration
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box>
							<Typography
								sx={{
									color:'black',
									ml:1,
									mt:1,
									fontWeight:'bold'
								}}							
							> 
								Selected Operator:
							</Typography>
							<Grid container spacing={0} sx={{ flexGrow: 1 }}>
								<Grid xs={4}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											Selected MRO Code:
										</FormLabel>
										<Autocomplete
											options={mro.map(data => ({ label: data.code }))}
											value={mro.find(item => item.code === editedMRO.code) ? { label: editedMRO.code} : null}
											sx={{...inputStyle, backgroundColor:'#c6ffff'}}
											onChange={(event, value) => handleChangeMRO('code', value ? value.label : '')}
											//onKeyDown={handleOperatorAdditionalInfo}
										/>
									</FormControl>
								</Grid>
								<Grid xs={3}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											STA - Main Office: 
										</FormLabel>
										<Input 
											size="sm" 
											disabled
											sx={{
												...inputStyle, 
												'--variant-outlinedDisabledColor':'black',
												'--variant-outlinedDisabledBorder':'black',
												bgcolor:'#d6d3ce',
											}} 
											value={editedMRO.code ? mro.find(item => item.code === editedMRO.code)?.code : ''}
										/>
									</FormControl>
								</Grid>
								<Grid xs={5}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											MRO Name: 
										</FormLabel>
										<Input 
											size="sm" 
											disabled
											sx={{
												...inputStyle, 
												'--variant-outlinedDisabledColor':'black',
												'--variant-outlinedDisabledBorder':'black',
												bgcolor:'#d6d3ce',
											}} 
											value={editedMRO.code ? mro.find(item => item.code === editedMRO.code)?.name : ''}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
						<Box
							sx={{
								display:'flex',
								flexDirection:'row',
								justifyContent:'center',
								maxHeight:'467px',
							}}
						>
							<Box
								sx={{
									height:'467px',
									maxHeight:'467px',
									width:'496px',
									px:1
								}}
							>
								<Typography
									sx={{
										fontWeight:'bold',
										color:'#000078',
										fontFamily:'Arial, sans-serif'
									}}							
								> 
									STA Registration List:
								</Typography>
								<Sheet
									className="OrderTableContainer"
									variant="outlined"
									sx={{
										minWidth: '0px',
										borderRadius: '0px',
										flexShrink: 1,
										overflow: 'auto',
										minHeight: 0,
										width:'100%',
										bgcolor:'#848284',
										height:'435px',
										border:'1px solid black'
									}}
								>
									<Table
										aria-labelledby="tableTitle"
										borderAxis="both"
										stickyHeader
										hoverRow
										sx={{
											'--TableCell-headBackground': '#d6d3ce',
											'--Table-headerUnderlineThickness': '1px',
											'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
											'--TableCell-paddingY': '1px',
											'--TableCell-height': '24px',
											'--TableCell-paddingX': '5px',
											'--TableCell-borderColor': '#939293',
											tableLayout: 'auto', 
											width:'auto',
											borderRadius:'0px'
										}}
									>
										<thead>
											<tr>
												<th style={headerStyle}>ID</th>
												<th style={headerStyle}>STA_Code</th>
												<th style={headerStyle}>STA_Name</th>
												<th style={headerStyle}>STA_Country</th>
												<th style={headerStyle}>Capability</th>
											</tr>
										</thead>
										<tbody>
											{tableData.map((row) => (
												<tr
													key={row.id}
													onClick={() => handleRowSelection(row)}
													style={{ 
														backgroundColor: selectedRow === row ? '#00cfd6' : 'white',
														color: selectedRow === row ? 'white' : 'black',
													}}
												>
													<td style={{...rowStyle, backgroundColor: selectedRow === row ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
													<td style={rowStyle}>{row.staCode}</td>
													<td style={rowStyle}>{row.staName}</td>
													<td style={rowStyle}>{row.staCountry}</td>
													<td style={rowStyle}>{row.capability}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</Sheet>
							</Box>
							<Box
								sx={{
									height:'467px',
									maxHeight:'467px',
									width:'300px'
								}}
							>
								<Typography 
									sx={{ 
										ml: 1,
										fontWeight:'bold',
										color:'#000078',
										fontFamily:'Arial, sans-serif'
									}}
								>
									STA Editor:
								</Typography>
								<Tooltip title='Add'>
									<IconButton
										variant='plain'
										disabled={editedData.code ? false : true}
										onClick={handleAdd}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px' 
											src="images/svg/add.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Update'>
									<IconButton 
										variant='plain'
										disabled={selectedRow.id && editedData.code ? false : true}
										onClick={handleUpdate}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px' 
											src="images/svg/save.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Delete'>
									<IconButton 
										variant='plain'
										disabled={selectedRow.id? false : true}
										onClick={handleDelete}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px'  
											src="images/svg/trashcan.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={7}>
										<FormControl sx={formControlStyle} required>
											<FormLabel sx={labelStyle}>
												STA:
											</FormLabel>
											<Autocomplete
												options={sta.map(data=> ({ label: data.code }))}
												value={editedData.code}
												sx={{...inputStyle, backgroundColor:'#c6ffff'}}
												onChange={(event, value) => handleChange('code', value ? value.label : '')}
												//onKeyDown={handleOperatorAdditionalInfo}
											/>
										</FormControl>
									</Grid>

									<Grid xs={12}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>
												STA Name: 
											</FormLabel>
											<Input 
												size="sm" 
												disabled
												sx={{
													...inputStyle, 
													'--variant-outlinedDisabledColor':'black',
													'--variant-outlinedDisabledBorder':'black',
													bgcolor:'#d6d3ce',
												}} 
												value={editedData.code ? sta.find(item => item.code === editedData.code)?.name : ''}
											/>
										</FormControl>
									</Grid>
									<Grid xs={12}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>
												STA Country: 
											</FormLabel>
											<Input 
												size="sm" 
												disabled
												sx={{
													...inputStyle, 
													'--variant-outlinedDisabledColor':'black',
													'--variant-outlinedDisabledBorder':'black',
													bgcolor:'#d6d3ce',
												}} 
												value={editedData.code ? sta.find(item => item.code === editedData.code)?.country : ''}
											/>
										</FormControl>
									</Grid>
									<Grid xs={12}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>
												Capability:
											</FormLabel>
											<Textarea
												maxRows={7}
												value={editedData.capability}
												sx={{ ...inputStyle, height: '189px', }}
												onChange={(event) => handleChange('capability', event.target.value)}
											/>
										</FormControl>
									</Grid>
								</Grid>	
							</Box>		
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}