import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import Dropdown from '@mui/joy/Dropdown';
import MenuItem from '@mui/joy/MenuItem';
import MenuButton from '@mui/joy/MenuButton';
import Box from '@mui/joy/Box';
import Menu from '@mui/joy/Menu';
import ListDivider from '@mui/joy/ListDivider';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Avatar from '@mui/joy/Avatar';
import {useNavigate} from 'react-router-dom';

import URL from '../../../../URL.js'

export default function Header(props) {
	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self`, { method: 'DELETE', headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				navigate('/SignIn');
			} else {
				console.error('Ошибка выполнения запроса');
			}
		} catch (error) {
			console.error('Произошла ошибка', error);
		}
	};
	return (
		<Sheet
			sx={{
				display: { xs: 'flex'},
				alignItems: 'center',
				justifyContent: 'space-between',
				position: 'fixed',
				top: 0,
				width: '100vw',
				height: 'var(--Header-height)',
				zIndex: 9995,
				p: 2,
				gap: 1,
				borderBottom: '1px solid',
				borderColor: 'background.level1',
				boxShadow: 'sm',
			}}
		>
			<GlobalStyles
				styles={(theme) => ({
					':root': {
						'--Header-height': '52px',
					},
				})}
			/>
			<Link href="/">
				<img width="170" src="images/logo.svg" alt="" />
			</Link>
			<Typography level="h2" color="neutral" textAlign ="center" sx={{ my: 2 }}>
				{props.title}
			</Typography>
			<Dropdown>
				<MenuButton
					variant="plain"
					size="sm"
					sx={{ maxWidth: '50px', maxHeight: '50px', borderRadius: '9999999px' }}
				>
					<Avatar sx={{ maxWidth: '50px', maxHeight: '50px' }} />
				</MenuButton>
				<Menu
					placement="bottom-end"
					size="sm"
					sx={{
						zIndex: '99999',
						p: 1,
						gap: 1,
						'--ListItem-radius': 'var(--joy-radius-sm)',
					}}
				>
					<MenuItem>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Avatar sx={{ borderRadius: '50%' }} />
							<Box sx={{ ml: 1.5 }}>
								<Typography level="title-sm" textColor="text.primary">
									{localStorage.getItem('name')}
								</Typography>
								<Typography level="body-xs" textColor="text.tertiary">
									{localStorage.getItem('login')}
								</Typography>
							</Box>
						</Box>
					</MenuItem>
					<ListDivider />
					<Link href="https://docs.alaskar.tech" target="_blank" underline="none">
						<MenuItem>
							<HelpRoundedIcon />
							Help
						</MenuItem>
					</Link>
					<MenuItem>
						<SettingsRoundedIcon />
						Settings
					</MenuItem>
					<ListDivider />
					<MenuItem onClick={handleLogout}>
						<LogoutRoundedIcon />
						Log out
					</MenuItem>
				</Menu>
			</Dropdown>
		</Sheet>
	);
}