import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function ErrorSuccessfulResponse(props) {
    const navigate = useNavigate();

    //Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				navigate('/SignIn');
				console.error(403);
			break;
			case 404:
				console.error(404);
			break;
			case 500:
				console.error(500);
			break;
		}
	};

    React.useEffect(() => {
        errors(props.responseStatus.errorStatus)
    }, [props.responseStatus]);

	// Функция для сброса ошибки и успешного сохранения через 3 секунды
	React.useEffect(() => {
        const resetStatus = () => {
            setTimeout(() => {
				props.setResponseStatus({errorStatus:0, successfulStatus:''});
            }, 3000);
        };

        if (props.responseStatus?.errorStatus != 0 || props.responseStatus?.successfulStatus != '') {
            resetStatus();
        }
    }, [props.responseStatus]);

    return(
        <>
            <Box
				sx={{
					position: 'fixed',
					top: '60px',
					right: '20px',
					display: props.responseStatus.errorStatus != 0 ? 'flex' : 'none',
					alignItems: 'center',
					color: 'red',
					backgroundColor:'#FCE4E4',
					px:1,
					borderRadius:'7px',
					zIndex: 9999,

				}}
			>
				<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
				<Typography sx={{ mt: 0.3, color: 'red' }}>
					Server error!
				</Typography>
			</Box>
			<Box
				sx={{
					position: 'fixed',
					top: '60px',
					right: '20px',
                    display: props.responseStatus.successfulStatus ? 'flex' : 'none',
					alignItems: 'center',
					color: 'red',
					backgroundColor:'#C6FCE4',
					px:1,
					borderRadius:'7px',
					zIndex: 9999,

				}}
			>
				<CheckCircleOutlineIcon sx={{ width:"24px", color:'green', mr: 1 }}/>
				<Typography sx={{ mt: 0.3, color: 'green' }}>
					Successful {props.responseStatus?.successfulStatus}
				</Typography>
			</Box>
        </>
    )
}