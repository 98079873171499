import * as React from 'react';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Menu from '@mui/joy/Menu';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Divider from '@mui/joy/Divider';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import FormLabel from '@mui/joy/FormLabel';
import ModalClose from '@mui/joy/ModalClose';
import MenuButton from '@mui/joy/MenuButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import ListDivider from '@mui/joy/ListDivider';
import ModalDialog from '@mui/joy/ModalDialog';
import ButtonGroup from '@mui/joy/ButtonGroup';
import CircularProgress from '@mui/joy/CircularProgress';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';

import SearchIcon from '@mui/icons-material/Search';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import URL from '../../../../../../URL.js';

import AddEditForm from './AddEditForm.tsx';

export default function OrderTable() {
	const [data, setData] = React.useState<any[]>([]);
	const [inputPage, setInputPage] = React.useState('');
	const [currentPage, setCurrentPage] = React.useState(1);
	const [loading, setLoading] = React.useState(true);
	const [order, setOrder] = React.useState('asc');
	const [addWindowOpen, setAddWindowOpen] = React.useState(false);
	const rowsPerPage = 19;
	const [selectedRowData, setSelectedRowData] = React.useState(null);

	const [filters, setFilters] = React.useState({
		id: '',
		item: '',
		pn:'',
	});

	const handleFilterChange = (column, value) => {
		setFilters((prevFilters) => ({
		  ...prevFilters,
		  [column]: value,
		}));
	};

	const handleRowClick = (rowData) => {
		setSelectedRowData(rowData);
	};

	const applyFilters = (row) => {
		return Object.keys(filters).every((column) => {
		  const filterValue = filters[column].toLowerCase();
		  const rowValue = String(row[column]).toLowerCase();
		  return rowValue.includes(filterValue);
		});
	};

	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const handleToggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	};

  
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${URL}/Store`, { credentials: 'include' });
				const result = await response.json();
				setLoading(false);
				setData(result);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);
	
	const renderPageButtons = () => {
		const totalPages = Math.ceil(data.filter(applyFilters).length / rowsPerPage);
		const maxVisiblePages = 6; // Максимальное количество отображаемых страниц
	  
		let startPage = Math.max(currentPage - 2, 1);
		let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
	  
		if (endPage - startPage < maxVisiblePages - 1) {
		  startPage = Math.max(endPage - maxVisiblePages + 1, 1);
		}
	  
		const buttons: React.ReactNode[] = [];
	  
		for (let i = startPage; i <= endPage; i++) {
		  buttons.push(
			<IconButton
			  key={i}
			  size="sm"
			  variant={currentPage === i ? 'outlined' : 'plain'}
			  color="neutral"
			  onClick={() => setCurrentPage(i)}
			>
			  {i}
			</IconButton>
		  );
		}
	    
		if (startPage > 1) {
		  buttons.unshift(<Typography key="start-ellipsis">...</Typography>);
		}

		if ((totalPages>maxVisiblePages)&&(currentPage>3)){
			buttons.unshift(
				<IconButton
					key={1}
					size="sm"
					variant={currentPage === 1 ? 'outlined' : 'plain'}
					color="neutral"
					onClick={() => setCurrentPage(1)}
				>
					1
			  	</IconButton>
			)
		}
	  
		if (endPage < totalPages) {
		  buttons.push(<Typography key="end-ellipsis">...</Typography>);
		}

		if ((totalPages>maxVisiblePages)&&(currentPage<totalPages-3)){
			buttons.push(
				<IconButton
					key={totalPages}
					size="sm"
					variant={currentPage === totalPages ? 'outlined' : 'plain'}
					color="neutral"
					onClick={() => setCurrentPage(totalPages)}
				>
					{totalPages}
			  	</IconButton>
			)
		}
	  
		if(buttons.length != 0){
			return buttons;
		} else {
			return(
				<IconButton
					key='1'
					size="sm"
					variant={currentPage === totalPages ? 'outlined' : 'plain'}
					color="neutral"
					onClick={() => setCurrentPage(1)}
				>
					1
			  	</IconButton>
			)
		}
	};
	
	const handlePageInputChange = (event) => {
		setInputPage(event.target.value);
	};

	const handlePageInputEnter = (event) => {
		if (event.key === 'Enter') {
			const pageNumber = parseInt(inputPage, 10);
			if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(data.length / rowsPerPage)) {
			setCurrentPage(pageNumber);
			}
		}
	};

	const startIndex = (currentPage - 1) * rowsPerPage;
	const endIndex = startIndex + rowsPerPage;
	
	const ColumnFilter = ({ filterKey, filters, handleFilterChange, children }) => {
		return (
			<Dropdown>
				<MenuButton
					variant="plain"
					color={filters[filterKey] ? 'primary' : 'neutral'}
					size="sm"
					sx={{ borderRadius: '9999999px', width: '5px' }}
				>
					{children}
				</MenuButton>
				<Menu
					placement="bottom-end"
					size="sm"
					sx={{
						zIndex: '99999',
						py: '0',
						'--ListItem-radius': 'var(--joy-radius-sm)',
						width: '100px',
					}}
				>
					<Box
						sx={{
						display: 'flex',
						alignItems: 'center',
						}}
					>
						<Input
							size="sm"
							value={filters[filterKey]}
							variant="plain"
							onChange={(e) => handleFilterChange(filterKey, e.target.value)}
							sx={{
								'--Input-minHeight': '0px',
							}}
						/>
					</Box>
				</Menu>
			</Dropdown>
		);
	};

	return (
		<React.Fragment>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
				sx={{
					position: 'relative',
					width: drawerOpen ? '70%' : '100%',
					transition: 'width 0.3s ease',
				}}
			>	
				{!loading&&(
					<ButtonGroup spacing="0.5rem" sx={{width:"100%", justifyContent:"right"}} >
						<Button variant="soft" color="primary" onClick={handleToggleDrawer} endDecorator={<ModeEditIcon sx={{width:'1'}}/>}>Edit</Button>
					</ButtonGroup>
				)}
				{loading? (
					<Box
						sx={{
							display: 'flex',
							justifyContent:'center',
							width: '100%',
							alignItems: 'center',
							height: '75vh',
						}}
					>
						<CircularProgress color="neutral" />
					</Box>
				):(
					<Sheet
						className="OrderTableContainer"
						variant="outlined"
						sx={{
							display: 'initial',
							width: '100%',
							borderRadius: 'sm',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: 0,
						}}
					>
						<Table
							aria-labelledby="tableTitle"
							stickyHeader
							hoverRow
							borderAxis="bothBetween"
							size="sm"
							sx={{
								
								'--TableCell-headBackground': 'var(--joy-palette-background-level1)',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '4px',
								'--TableCell-paddingX': '8px',
								tableLayout: 'auto',
								whiteSpace: 'nowrap',
							}}
						>
							<thead>
								<tr>
									<th style={{ padding: '12px 6px', minWidth: '50px' ,}}>
										ID
										{/*<ColumnFilter filterKey="id" filters={filters} handleFilterChange={handleFilterChange}>
											<FilterAltIcon />
										</ColumnFilter>*/}
										<Dropdown sx={{position: 'absolute',right: 0,}}>
											<MenuButton
												variant="plain"
												color={filters.id? "primary" : "neutral"}
												size="sm"
												sx={{ 
													borderRadius: '9999999px', 
													width:'5px',
													position: 'absolute',
													right: 0,
													bottom:6
												}}
											>
												<FilterAltIcon />
											</MenuButton>
											<Menu
												placement="bottom-end"
												sx={{
													zIndex: '99999',
													py:'0',
													'--ListItem-radius': 'var(--joy-radius-sm)',
													width:'100px',
												}}
											>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Input
														size="sm"
														value={filters.id}
														variant="plain"
														onChange={(e) => handleFilterChange('id', e.target.value)}
														sx={{
															"--Input-minHeight": "0px",
														}}
														endDecorator={
															<IconButton size="sm" onClick={() => handleFilterChange('id','')}>
																<ArrowBackIcon sx={{ width:"17px" }} />
															</IconButton>
														}
													/>
												</Box>
											</Menu>
										</Dropdown>
									</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Item</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>
									
										PO ID
										<Link
											underline="none"
											color="primary"
											component="button"
											onClick={() => {
												setOrder(order === 'asc' ? 'desc' : 'asc');
												setData((prevData) => {
													const sortedData = [...prevData];
														sortedData.sort((a, b) => {
														const aValue = a.poId || '';
														const bValue = b.poId || '';
														return order === 'desc' ? (aValue-bValue) : (bValue - aValue);
														});
												return sortedData;
												});
											}}
											fontWeight="lg"
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
												transition: '0.2s',
												transform:
													order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										/>
									</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>PO Item ID</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>
										PN
										<Dropdown sx={{position: 'absolute',right: 0,}}>
											<MenuButton
												variant="plain"
												color={filters.pn? "primary" : "neutral"}
												size="sm"
												sx={{ 
													borderRadius: '9999999px', 
													width:'5px',
													position: 'absolute',
													right: 0,
													bottom:6
												}}
											>
												<FilterAltIcon />
											</MenuButton>
											<Menu
												placement="bottom-end"
												sx={{
													zIndex: '99999',
													py:'0',
													'--ListItem-radius': 'var(--joy-radius-sm)',
													width:'100px',
												}}
											>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Input
														size="sm"
														value={filters.pn}
														variant="plain"
														onChange={(e) => handleFilterChange('pn', e.target.value)}
														sx={{
															"--Input-minHeight": "0px",
														}}
														endDecorator={
															<IconButton size="sm" onClick={() => handleFilterChange('pn','')}>
																<ArrowBackIcon sx={{ width:"17px" }} />
															</IconButton>
														}
													/>
												</Box>
											</Menu>
										</Dropdown>
									</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Description</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Batch Number</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>QTY</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Reserved QTY</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Stored QTY</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Unit</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Store</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Store Addres</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>MFG Date</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Store Time</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Expire Date</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Condition</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Certificate Type</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Certificate Date</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Certificate Approval Ref</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>Certificate Number</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Serial Number</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>Remarks</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>TSN</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>CSN</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>TSOH</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>CSOH</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>Stored Date</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Approval Signature</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>Invoice Status</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Invoice ID</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Component Action</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Order Reference</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Supplier Code</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Job Number</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Property of</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Tool ID</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Tool BN</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Orb ID</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>TB Batch Number</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>TSR</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>CSR</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Owner</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>ID Tag</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Inventory ID</th>
									<th style={{ padding: '12px 6px', minWidth: '50px'}}>Ext Batch Number</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Is Pool</th>
									<th style={{ padding: '12px 6px', minWidth: '50px' }}>Vendor</th>
								</tr>
							</thead>
							<tbody>
								{
									data.filter(applyFilters)
									.slice(startIndex, endIndex)
									.map((row) => (
										<tr key={(row as any).yourUniqueKey}  onClick={() => handleRowClick(row)}   style={{ backgroundColor: selectedRowData === row ? 'lightblue' : 'white' }}>
											<td>{(row as any).id}</td>
											<td>{(row as any).item}</td>
											<td>{(row as any).poId}</td>
											<td>{(row as any).poItemId}</td>
											<td>{(row as any).pn}</td>
											<td>{(row as any).description}</td>
											<td>{(row as any).batchNumber}</td>
											<td>{(row as any).qty}</td>
											<td>{(row as any).reservedQty}</td>
											<td>{(row as any).storedQty}</td>
											<td>{(row as any).unit}</td>
											<td>{(row as any).store}</td>
											<td>{(row as any).storeAddress}</td>
											<td>{(row as any).mfgDate}</td>
											<td>{(row as any).storeTime}</td>
											<td>{(row as any).expireDate}</td>
											<td>{(row as any).condition}</td>
											<td>{(row as any).certificateType}</td>
											<td>{(row as any).certificateDate}</td>
											<td>{(row as any).certificateApprovalRef}</td>
											<td>{(row as any).certificateNumber}</td>
											<td>{(row as any).serialNumber}</td>
											<td>{(row as any).remarks}</td>
											<td>{(row as any).tsn}</td>
											<td>{(row as any).csn}</td>
											<td>{(row as any).tsoh}</td>
											<td>{(row as any).csoh}</td>
											<td>{(row as any).storedDate}</td>
											<td>{(row as any).storedTime}</td>
											<td>{(row as any).approvalSignature}</td>
											<td>{(row as any).invoiceStatus}</td>
											<td>{(row as any).invoiceId}</td>
											<td>{(row as any).componentAction}</td>
											<td>{(row as any).orderReference}</td>
											<td>{(row as any).supplierCode}</td>
											<td>{(row as any).jobNumber}</td>
											<td>{(row as any).propertyOf}</td>
											<td>{(row as any).toolId}</td>
											<td>{(row as any).toolBN}</td>
											<td>{(row as any).orbId}</td>
											<td>{(row as any).tbBatchNumber}</td>
											<td>{(row as any).tsr}</td>
											<td>{(row as any).csr}</td>
											<td>{(row as any).owner}</td>
											<td>{(row as any).idTag}</td>
											<td>{(row as any).inventoryId}</td>
											<td>{(row as any).extBatchNumber}</td>
											<td>{(row as any).isPool}</td>
											<td>{(row as any).vendor}</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					</Sheet>
				)}
				<Box
					className="Pagination-laptopUp"
					sx={{
						[`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
						display: 'flex',
						justifyContent:'center',
						width: '100%',
						alignItems: 'bottom',
					}}
				>
					<Button
						size="sm"
						variant="plain"
						color="neutral"
						startDecorator={<KeyboardArrowLeftIcon />}
						onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
					/>
					
					{loading?(
						<CircularProgress color="neutral" />
					):(
						renderPageButtons()
					)}

					<Button
						size="sm"
						variant="plain"
						color="neutral"
						endDecorator={<KeyboardArrowRightIcon />}
						onClick={() =>
							setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / rowsPerPage)))
						}
					/>

				</Box>
				<Box
					sx={{
						display: {
							xs: 'none',
							md: 'flex',
						},
						justifyContent:'center',
						width: '100%'
					}}
				>
					<Input
						size="sm"
						value={inputPage}
						onChange={handlePageInputChange}
						onKeyDown={handlePageInputEnter}
						placeholder="Page"
						sx={{ width: '51px', textAlign: 'center' }}
					/>
				</Box>
			</Stack>
			{drawerOpen && (
				<Box
					sx={{
						position: 'absolute',
						top: '51px',
						bottom: 0,
						right: drawerOpen ? '0' : '-400px',
						width: '30vw', 
						transition: 'right 0.3s ease', 
					}}
				>
					<AddEditForm selectedRowData={selectedRowData} />
				</Box>
			)}
		</React.Fragment>
	);
}