import * as React from 'react';

import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Divider from '@mui/material/Divider';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import FormControl from '@mui/joy/FormControl';
import { CssVarsProvider } from '@mui/joy/styles';
import Radio,{ radioClasses } from '@mui/joy/Radio';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import AMP from './components/AMP.tsx'
import Header from '../Components/Header.tsx';
import Find_Components from './components/Find_Components.tsx';
import AC_Maintenance_Data from './components/AC_Maintenance_Data.tsx'
import Actual_Structure from './components/Actual_Structure.tsx';

import URL from '../../../../URL.js';

export default function Part_M() {
	document.title = "Part-M";
    const [window, setWindow] = React.useState('AS');
	const [openFilters, setOpenFilters] = React.useState(false);
	const [openMaintanaceData, setOpenMaintanaceData] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [permissions, setPermissions] = React.useState([{module:'',window:'',access:''}]);
	const [user, setUser] = React.useState({login:'', name:'', userSTA:''});
	const [selectedAC, setSelectedAC] = React.useState({id:'', asID: '', acReg:''}); //Данные строки выбранной в Actual_Structure.tsx
	
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setData(result.value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	React.useEffect(() => {
		const fetchUser = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setUser(result);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchUser();
	}, []);

	React.useEffect(() => {
		const fetchPermissions = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self/Permissions?&filter=module eq 'Part-M'`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setPermissions(result.value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchPermissions();
	}, []);

	const handleFindComponentsClick = () => {
        setOpenFilters(!openFilters);
    };

	return (
		
			<Box
				sx={{
					display: 'grid',
					gridTemplateRows: '57px 1px 10px 1fr',
					height: '100vh',
					maxHeight:'100vh',
					backgroundColor:'#000042',
					overflow:'hidden'
				}}
			>
				<Box sx={{backgroundColor:'white'}}>
					<Header title='Part-M'/>
				</Box>
                <Divider/>
                <Breadcrumbs
					size="sm"
					aria-label="breadcrumbs"
					separator={<ChevronRightRoundedIcon sx={{color:'white'}}/>}
					sx={{ pl: 0, mx: 2,}}
				>
					<Link
						underline="none"
						href="/"
						aria-label="Home"
					>
						<HomeRoundedIcon sx={{color:'white'}}/>
					</Link>
					<Typography sx={{color:"white"}} fontWeight={500} fontSize={12}>
						Part-M
					</Typography>
				</Breadcrumbs>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 250px',
                        mr:2,
						height:'auto',
						maxHeight:'850px',
						overflow:'hidden'
                    }}
                >
					<Box 
						sx={{
							backgroundColor:'#848284',
							my:4,
							mx:2,
							height:'auto',
							outline:'1px solid white',
							overflow:'hidden'
						}}
					>
						<Box
							sx={{
								display: 'grid',
								gridTemplateRows: '0fr 0fr 4.72fr',
								//border: '1px solid white' ,
								mt:2,
								height:'auto',
								overflow:'hidden'
							}}
						>
							<Box
								sx={{
									border: '1px solid white', 
									mt:3,
									mx:2,
									py:1.5,
									px:2
								}}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<FormControl>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'20px',
												margin:'0px'
											}}
										>
											User ID:
										</FormLabel>
										<Textarea 
											minRows={1} 
											size="md" 
											variant='outlined' 
											value={user.login}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'#000084',
												'--variant-outlinedDisabledBorder':'white',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'270px',
												borderRadius:'0px',
												borderRight:'2px solid white',
												borderBottom:'2px solid white',
												backgroundColor:'#c6ffff',
												fontWeight:'bold',
												fontSize:'23px'
											}}
										/>
									</FormControl>
									<FormControl>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'20px',
												margin:'0px'
											}}
										>
											User Name:
										</FormLabel>
										<Textarea 
											minRows={1}
											size="md" 
											disabled 
											variant='outlined' 
											value={user.name}
											sx={{
												'--variant-outlinedDisabledColor':'black',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'770px',
												backgroundColor:'#e7e3e7',
												borderRadius:'0px',
												border:'1px solid black',
												fontSize:'24px',
												fontWeight:'bold',
												'& textarea': {
													textAlign: 'center',
												}
											}}
										/>

									</FormControl>
									<FormControl>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'20px',
												margin:'0px'
											}}
										>
											User STA:
										</FormLabel>
										<Textarea 
											minRows={1} 
											size="md" 
											variant='outlined' 
											value={user.userSTA}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'#000084',
												'--variant-outlinedDisabledBorder':'white',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'270px',
												borderRadius:'0px',
												borderRight:'2px solid white',
												borderBottom:'2px solid white',
												backgroundColor:'#c6ffff',
												fontWeight:'bold',
												fontSize:'23px',
											}}
										/>
									</FormControl>
								</Stack>
							</Box>
							<Box sx={{mx:2,my:1}}>
								<RadioGroup
									orientation="horizontal"
									aria-label="Alignment"
									name="alignment"
									variant="outlined"
									value={window}
									sx={{
										width:'50%',
										borderRadius:'0',
										backgroundColor:'#d6d3ce',
										height:'25px',
										borderBottom:'2px solid #d8d6d4'
									}}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										setWindow(event.target.value)
									}
								>
									{['AS', 'AMP'].map((item) => (
										<Box
											key={item}
											sx={(theme) => ({
												position: 'relative',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												width: 'auto',
												px:1,
												height: 25,
												'&:not([data-first-child])': {
												borderLeft: '1px solid',
												borderColor: 'divider',
												},
												[`&[data-first-child] .${radioClasses.action}`]: {
												borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
												borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
												},
												[`&[data-last-child] .${radioClasses.action}`]: {
												borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
												borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
												},
											})}
										>
											<Radio
												value={item}
												disableIcon
												overlay
												label={
												{
													AS: 'Actual Structure',
													AMP: 'AMP-Maintenace Program',
												}[item]
												}
												variant={window === item ? 'solid' : 'plain'}
												slotProps={{
												input: { 'aria-label': item },
												action: {
													sx: { borderRadius: 0, transition: 'none' },
												},
												label: { sx: { lineHeight: 0 } },
												}}
											/>
										</Box>
									))}
								</RadioGroup>
							</Box>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
								}}
							>
								<Box
									sx={{
										border: '1px solid white',
										mt:1,
										ml:2,
										mb:3 
									}}
								>
									{window == 'AS' ? (
										<Actual_Structure 
											data={data} 
											permissions={permissions} 
											handleFindComponentsClick={handleFindComponentsClick} 
											setOpenMaintanaceData={setOpenMaintanaceData}
											openFilters={openFilters}
											setSelectedAC={setSelectedAC}
										/>
									) : (
										<AMP data={data}/>
									)}
								</Box>
								<Box
									sx={{
										border: '1px solid white',
										mt: 1,
										mx: 2,
										mb: 3,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{openFilters && (<Find_Components/>)}
									{openMaintanaceData && (<AC_Maintenance_Data setOpenMaintanaceData={setOpenMaintanaceData} selectedAC={selectedAC}/>)}
									{!openFilters && !openMaintanaceData &&
										(
											<img
												width='400'
												src='images/logo_alaskar.svg'
												alt=""
											/>
										)
									}
								</Box>     
							</Box>                                        
						</Box>
					</Box>
                    <Box
                        sx={{
							mb:4
                        }}
                    >
                        <Stack
							direction="column"
							justifyContent="flex-end"
							alignItems="center"
							spacing={2}
							height = '100%'
						>
							{permissions.some(permission => permission.window === "TLOG") ?(
								<Button
									key='TLOG'
									variant="outlined"
									sx={{
										borderRadius: '0px',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
										width:'100%',
										height:'85px',
										backgroundColor:'#8482ff',
										fontSize:'30px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										color:'black'
									}}
								>
									Tlog
								</Button>
							):(
								<Box 
									sx={{
										width:'100%',
										height:'85px',
									}}
								/>
							)}
							{permissions.some(permission => permission.window === "NRC") ?(
								<Button
									key='NRC'
									variant="outlined"
									sx={{
										borderRadius: '0px',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
										width:'100%',
										height:'85px',
										backgroundColor:'#8482ff',
										fontSize:'30px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										color:'black'
									}}
								>
									NRC
								</Button>
							):(
								<Box 
									sx={{
										width:'100%',
										height:'85px',
									}}
								/>
							)}
							{permissions.some(permission => permission.window === "ACTimes") ?(
								<Button
									key='A/C Times'
									variant="outlined"
									sx={{
										borderRadius: '0px',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
										width:'100%',
										height:'85px',
										backgroundColor:'#8482ff',
										fontSize:'30px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										color:'black'
									}}
								>
									A/C Times
								</Button>
							):(
								<Box 
									sx={{
										width:'100%',
										height:'85px',
									}}
								/>
							)}
							{permissions.some(permission => permission.window === "MaterialManagement") ?(
								<Button
									key='Material Management'
									variant="outlined"
									sx={{
										borderRadius: '0px',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
										width:'100%',
										height:'85px',
										backgroundColor:'#8482ff',
										fontSize:'30px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										color:'black'
									}}
								>
									Material Management
								</Button>
							):(
								<Box 
									sx={{
										width:'100%',
										height:'85px',
									}}
								/>
							)}
							{permissions.some(permission => permission.window === "Shortage") ?(
								<Button
									key='Shortage'
									variant="outlined"
									sx={{
										borderRadius: '0px',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
										width:'100%',
										height:'85px',
										backgroundColor:'#8482ff',
										fontSize:'30px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										color:'black'
									}}
								>
									Shortage
								</Button>
							):(
								<Box 
									sx={{
										width:'100%',
										height:'85px',
									}}
								/>
							)}
						</Stack>
                    </Box>
                </Box>
			</Box>
		
	);
}
