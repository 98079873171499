import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';


import URL from '../../../../../../URL.js';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'16px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'16px' }
	const formControlStyle = { my: 1 }

	const [countries, setCountries] = React.useState<{name: string}[]>([]) 
	const [editedData, setEditedData] = React.useState({id:0, code:'', name:'', country:'', remarks:'', activate:''});
	const [meta, setMeta] = React.useState([{name:'',max:0}]);
	const navigate = useNavigate();

	//Внесение изменений в data
	const handleChange = (property, value) => {
		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	//Изменение чекбокса Activate
	const handleCheckboxChange = (event) => {
		const activateValue = event.target.checked ? 'Y' : 'N';
		handleChange('activate', activateValue);
	};

	React.useEffect(() => {
		setEditedData({
			id: props.selectedRow.id,
			code: props.selectedRow.code,
			name: props.selectedRow.name,
			country: props.selectedRow.country,
			remarks: props.selectedRow.remarks,
			activate: props.selectedRow.activate,
		});
	}, [props.selectedRow]);

	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				errors(response.status);
			}
		};
		fetchMeta();
	}, []);

	React.useEffect(() => {
		const fetchCountry = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ASYSCountries`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setCountries(result.value);
			} else {
				errors(response.status);
			}
		};
		fetchCountry();
	}, []);

	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: editedData.code,
					name: editedData.name,
					country: editedData.country,
					remarks: editedData.remarks,
					activate: editedData.activate,			
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: editedData.code,
					name: editedData.name,
					country: editedData.country,
					remarks: editedData.remarks,
					activate: editedData.activate,
				})
			  }
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка обновления группы:', error));

	}

	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				errors(response.status);
			} else {
				//setSuccessfulSave(true);
			}
			props.reloadTable();
			return response.json();
		})
		.then(data => console.log(data))
		.catch(error => console.error('Ошибка удаления группы:', error));

	}

	//Обработчик ошибок
	const errors = (status) => {
		switch (status) {
			case 400:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(400);
			break;
			case 401:
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				console.error('401 Unauthorized');
			break;
			case 403:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(403);
			break;
			case 404:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(404);
			break;
			case 500:
				props.setError(prevState => ({ ...prevState, serverError: true }));
				console.error(500);
			break;
		}
	};

	return (
		<Box>
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				STA Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.code && editedData.name && editedData.country ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.code && editedData.name && editedData.country ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={8}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							STA Code:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.code}
							onChange={(event) => handleChange('code', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4} sx={{display:'flex', justifyContent:'center'}}>
					<Checkbox 
						label="Activate"
						variant="outlined"
						color='danger'
						defaultChecked 
						checked={editedData.activate == 'Y' ? true:false}
						onChange={handleCheckboxChange}
						sx={{
							mt:1,
							color:'#840000',
							fontWeight:'bold'
						}}
					/>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							STA Name:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData.name}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							STA Country:
						</FormLabel>
						<Autocomplete
							options={countries.map(data=> ({ label: data.name }))}
							value={countries.find(item => item.name === editedData.country) ? { label: editedData.country } : null}
							sx={inputStyle}
							onChange={(event, value) => handleChange('country', value ? value.label : '')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Remarks:
						</FormLabel>
						<Textarea
							maxRows={10}
							value={editedData.remarks}
							sx={{
								...inputStyle, 
								height: '250px', 
								lineHeight: '1.2'
							}}
							onChange={(event) => handleChange('remarks', event.target.value)}
						>
						</Textarea>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
